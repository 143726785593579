import "./index.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className='main-footer'>
            <Container>
                <Row>
                    <Col>
                        <ul className='footer-menu'>
                            <li>
                                {`Copyright 2023 | Mammoth Distribution | License #C11-0001429-LIC`}
                            </li>
                            <li>
                                <Link to='/profile/data'>Data</Link>
                            </li>
                            <li>
                                <Link to='/profile/privacy'>Privacy</Link>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Footer;
