/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBetween } from "use-between";
import {
    Col,
    Container,
    Nav,
    Navbar,
    NavDropdown,
    Row,
    Image
} from "react-bootstrap";
import { AccountMenu, CartIcon, SearchIcon, ToggleIcon, XIcon } from "../icons";
import useApi from "../../pages/useApi";
import "./index.scss";
import AccountSelector from "../account-selector";
import CartTimer from "../cart-timer";
import { toTwoDecimals } from "../../plugins";
import SearchBar from "../search-bar";
import Logo from "../../assets/images/profile/logo.png";

const Header = ({ fluid, cart, setCart }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [opened, setOpened] = useState(false);
    const [searchBarOpen, setSearchBarOpen] = useState(false);
    const {
        userId,
        cartAmount,
        logout,
        childCustomers,
        customer,
        customerId,
        setCustomerId,
        multipleCustomers,
        setMultipleCustomers,
        cartTimeReset,
        setCartTimeReset
    } = useBetween(useApi);

    const handleCart = (e) => {
        e.preventDefault();
        setCart(!cart);
    };

    const handleMenu = (e) => {
        e.preventDefault();
        setOpened(true);
    };

    const changeCustomer = (id) => {
        setCustomerId(id);
        setOpened(false);
        localStorage.setItem("customerId", id);
    };

    const handleLogout = (e) => {
        logout().then(() => {
            navigate("/");
        });
    };

    useEffect(() => {
        if (userId && childCustomers.length > 0) {
            setMultipleCustomers(true);
        } else {
            setMultipleCustomers(false);
        }
    }, [userId, childCustomers]);

    useEffect(() => {
        setCartTimeReset(false);
    }, [cartTimeReset]);

    return (
        <>
            {multipleCustomers ? <AccountSelector /> : null}
            <Navbar
                expand='md'
                className={multipleCustomers ? "hasMultipleCustomers" : ""}
            >
                <Container
                    fluid={fluid ? true : false}
                    className='position-relative'
                >
                    <Row className='nav-wrapper'>
                        <Col md={6} xs={2}>
                            <Row className='brand-navigation align-items-center'>
                                <Navbar.Brand href='/'>
                                    <Image
                                        width='40px'
                                        src={Logo}
                                        alt='Mammoth logo'
                                        className='brand-logo'
                                    />
                                </Navbar.Brand>
                                <Nav className='me-auto'>
                                    <Link to='/' className='nav-link'>
                                        Home
                                    </Link>
                                    <Link
                                        to='/collection/all'
                                        className={`nav-link ${
                                            location.pathname ===
                                            "/collection/all"
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        Products
                                    </Link>
                                    <Link
                                        to='/profile/orders'
                                        className={`nav-link ${
                                            location.pathname ===
                                            "/profile/orders"
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        Orders
                                    </Link>
                                    <Link
                                        to='/profile/details'
                                        className={`nav-link ${
                                            location.pathname ===
                                            "/profile/details"
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        Account
                                    </Link>
                                </Nav>
                            </Row>
                        </Col>
                        <Col
                            md={6}
                            xs={10}
                            className='d-flex align-items-center justify-content-end'
                        >
                            <SearchBar />

                            <Link
                                to='/cart'
                                className='nav-cta btn-cart position-relative'
                                onClick={(e) => {
                                    location.pathname !== "/checkout"
                                        ? handleCart(e)
                                        : e.preventDefault();
                                }}
                            >
                                <CartIcon width='24' height='24' />
                                <span className='quantity'>{cartAmount}</span>
                            </Link>
                            {cartAmount > 0 && (
                                <div className='cart-timer-box'>
                                    <CartTimer showTimeOnly={true} />
                                </div>
                            )}

                            <Link
                                to='/'
                                className='nav-cta d-md-none'
                                onClick={(e) => handleMenu(e)}
                            >
                                <ToggleIcon width='24' height='24' />
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
            <div className={`mobile-menu ${opened ? "show" : ""}`}>
                <div className='mobile-menu-header d-flex justify-content-between align-items-center'>
                    <h3>Menu</h3>
                    <XIcon
                        className='cursor-pointer'
                        width='24'
                        height='24'
                        onClick={() => setOpened(false)}
                    />
                </div>
                <div className='mobile-menu-wrapper d-flex flex-column'>
                    <Link to='/' className='mobile-menu-link'>
                        Home
                    </Link>
                    <Link to='/profile/details' className='mobile-menu-link'>
                        Profile
                    </Link>
                    <div className='d-flex position-relative'>
                        <Link
                            to='/cart'
                            className='mobile-menu-link'
                            onClick={(e) => handleCart(e)}
                        >
                            Cart
                        </Link>
                        <span className='quantity'>{cartAmount}</span>
                    </div>
                    <Link to='/profile/orders' className='mobile-menu-link'>
                        Order History
                    </Link>
                    <hr className='mobile-menu-divider' />
                    <Link
                        to='/profile/favorites'
                        className='mobile-menu-bottom'
                    >
                        Favourites
                    </Link>
                    <Link to='/profile/password' className='mobile-menu-bottom'>
                        Password
                    </Link>
                    {/* <hr className='mobile-menu-divider' />
                    {childCustomers.length
                        ? childCustomers.map((child, index) => (
                              <div
                                  className={`mobile-menu-link ${
                                      child.customerId === customerId
                                          ? "active"
                                          : ""
                                  }`}
                                  key={index}
                                  onClick={() =>
                                      changeCustomer(child.customerId)
                                  }
                              >
                                  <span>{child.accountRef}</span>
                              </div>
                          ))
                        : null} */}
                    {/* <Link to="/profile/notifications" className="mobile-menu-bottom">Notifications</Link> */}
                    <hr className='mobile-menu-divider' />
                    <Link
                        onClick={handleLogout}
                        className='mobile-menu-link logout'
                    >
                        Log Out
                    </Link>
                </div>
            </div>
            {customer.PastDueBalance &&
                customer.TotalOpenBalance &&
                customer.PastDueBalance > 0 && (
                    <div
                        className={`ar-block text-center ${
                            multipleCustomers ? "hasMultipleCustomers" : ""
                        }`}
                    >
                        {`You have a past due balance of $${toTwoDecimals(
                            parseFloat(customer.PastDueBalance)
                        )}. Your total open balance with Mammoth is $${toTwoDecimals(
                            parseFloat(customer.TotalOpenBalance)
                        )}.`}
                    </div>
                )}
        </>
    );
};

export default Header;
