import { useEffect, useState } from "react";
import "./index.scss";
import { Image, OverlayTrigger, Popover, ProgressBar } from "react-bootstrap";
import GiftBoxIcon from "../../assets/images/gift/giftBoxIcon.svg";
import WhiteLock from "../../assets/images/gift/white-lock.svg";
import GoldenLock from "../../assets/images/gift/golden-lock.svg";
import { CheckIcon } from "../icons";
import ReactCustomizableProgressbar from "react-customizable-progressbar";
import useApi from "../../pages/useApi";
import { useBetween } from "use-between";
import ConfettiExplosion from "react-confetti-explosion";
import { toFormattedNumbers, toTwoDecimals, kFormatter } from "../../plugins";

const Gamification = ({ rewardsThreshold }) => {
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [rewardList, setRewardList] = useState({
        threshold1: [],
        threshold2: [],
        threshold3: []
    });
    const [completed1Celebration, setCompleted1Celebration] = useState(false);
    const [completed2Celebration, setCompleted2Celebration] = useState(false);
    const [completed3Celebration, setCompleted3Celebration] = useState(false);
    const [isExploding, setIsExploding] = useState(false);
    const [totalPercent, setTotalPercent] = useState({
        firstProgressBarPercent: 0,
        secondProgressBarPercent: 0,
        thirdProgressBarPercent: 0
    });
    const {
        getCustomerRewards,
        customerRewardItem,
        setCustomerRewardItem,
        cart,
        cartOpen,
        cartProducts,
        setCartAmount,
        logout,
        showGamificationPopOver,
        setShowGamificationPopOver
    } = useBetween(useApi);

    useEffect(() => {
        getCustomerRewards()
            .then((data) => {
                if (data.type === "success") {
                    const formattedGiftItems = {
                        threshold1: [],
                        threshold2: [],
                        threshold3: []
                    };
                    data.rewards
                        .filter((reward) => reward.ItemName && reward.ImageLink)
                        .map((giftItem) => {
                            if (giftItem.Threshold.value === 1) {
                                formattedGiftItems.threshold1.push(giftItem);
                            } else if (giftItem.Threshold.value === 2) {
                                formattedGiftItems.threshold2.push(giftItem);
                            } else {
                                formattedGiftItems.threshold3.push(giftItem);
                            }
                        });
                    setRewardList(formattedGiftItems);
                }
            })
            .catch(() => logout());
    }, []);

    const calculatePercentage = (totalPrice, levelMark) => {
        return ((totalPrice / levelMark) * 100).toFixed(2);
    };

    const isQualifiedForGift = (totalProgressBarPercent) => {
        return parseInt(totalProgressBarPercent) >= 100;
    };

    useEffect(() => {
        let price = 0;
        let originalPrice = 0;

        if (Object.keys(cart).length) {
            cart.products.map((item) => {
                price +=
                    item.quantity *
                    cartProducts[item.productId].DefaultPrice.value;
                originalPrice +=
                    item.quantity *
                    cartProducts[item.productId].OriginalPrice.value;
            });

            setTotalPrice(price);
            setOriginalPrice(originalPrice);
            setCartAmount(cart.products.length);
        }
    }, [cart]);

    useEffect(() => {
        if (totalPrice !== 0) {
            if (
                totalPrice < rewardsThreshold.threshold3 &&
                customerRewardItem?.Threshold.value >= 3
            ) {
                setCustomerRewardItem(undefined);
            }
            if (
                totalPrice < rewardsThreshold.threshold2 &&
                customerRewardItem?.Threshold.value >= 2
            ) {
                setCustomerRewardItem(undefined);
            }
            if (
                totalPrice < rewardsThreshold.threshold1 &&
                customerRewardItem?.Threshold.value >= 1
            ) {
                setCustomerRewardItem(undefined);
            }
        }
    }, [totalPrice, rewardsThreshold, customerRewardItem]);

    useEffect(() => {
        if (totalPrice > rewardsThreshold.threshold2) {
            setTotalPercent({
                firstProgressBarPercent: "100",
                secondProgressBarPercent: "100",
                thirdProgressBarPercent: getPercentageOfProgressBar(totalPrice)
            });
        } else if (totalPrice > rewardsThreshold.threshold1) {
            setTotalPercent({
                firstProgressBarPercent: "100",
                secondProgressBarPercent: getPercentageOfProgressBar(
                    totalPrice
                ),
                thirdProgressBarPercent: "0"
            });
        } else {
            setTotalPercent({
                firstProgressBarPercent: getPercentageOfProgressBar(totalPrice),
                secondProgressBarPercent: "0",
                thirdProgressBarPercent: "0"
            });
        }

        if (totalPrice >= rewardsThreshold.threshold3) {
            if (!completed3Celebration) {
                setIsExploding(true);
            }
            setCompleted3Celebration(true);
        } else if (totalPrice >= rewardsThreshold.threshold2) {
            if (!completed2Celebration) {
                setIsExploding(true);
            }
            setCompleted2Celebration(true);
        } else if (totalPrice >= rewardsThreshold.threshold1) {
            if (!completed1Celebration) {
                setIsExploding(true);
            }

            setCompleted1Celebration(true);
        }
    }, [totalPrice]);

    const getWidthOfProgressBar = (value, totalValue) =>
        `${(value / totalValue) * 100}%`;

    const popover = (
        <Popover className='gamification-popover'>
            <Popover.Header as='h3'>
                {totalPrice < rewardsThreshold.threshold1
                    ? `Add  $${toTwoDecimals(
                          rewardsThreshold.threshold1 - totalPrice
                      )} to unlock rewards`
                    : `Congrats! You’ve unlocked rewards`}
            </Popover.Header>
            <Popover.Body>
                <div className='linear-progressbar-box'>
                    <ul>
                        <li
                            className='firstStep'
                            style={{
                                width: "70%"
                            }}
                        >
                            <span className='initial-amount'>$0</span>
                            <ProgressBar
                                animated
                                striped
                                variant='warning'
                                now={totalPercent.firstProgressBarPercent}
                                className='progress-bar-striped progress-bar-animated '
                            />
                            {/* <span>{`$${toFormattedNumbers(
                                rewardsThreshold.threshold1
                            )}`}</span> */}
                            <span>
                                ${kFormatter(rewardsThreshold.threshold1)}
                            </span>
                        </li>
                        <li
                            className='secondStep'
                            style={{
                                width: "15%"
                            }}
                        >
                            <ProgressBar
                                animated
                                striped
                                variant='warning'
                                now={totalPercent.secondProgressBarPercent}
                                className='progress-bar-striped progress-bar-animated '
                            />
                            {/* <span>{`$${toFormattedNumbers(
                                rewardsThreshold.threshold2
                            )}`}</span> */}
                            <span>
                                ${kFormatter(rewardsThreshold.threshold2)}
                            </span>
                        </li>
                        <li
                            className='thirdStep'
                            style={{
                                width: "15%"
                            }}
                        >
                            <ProgressBar
                                animated
                                striped
                                variant='warning'
                                now={totalPercent.thirdProgressBarPercent}
                                className='progress-bar-striped progress-bar-animated '
                            />
                            {/* <span>{`$${toFormattedNumbers(
                                rewardsThreshold.threshold3
                            )}`}</span> */}
                            <span>
                                ${kFormatter(rewardsThreshold.threshold3)}
                            </span>
                        </li>
                    </ul>
                    {totalPrice > 0 && (
                        <div className='amount-spent mt-2'>{`$${toFormattedNumbers(
                            totalPrice
                        )} added to cart`}</div>
                    )}
                </div>
                <div className='gift-wrapper'>
                    <div className='gift-level'>
                        <label>
                            {rewardsThreshold.threshold1 - totalPrice <= 0
                                ? `Select one item`
                                : `Add $${toTwoDecimals(
                                      rewardsThreshold.threshold1 - totalPrice
                                  )} to unlock`}
                        </label>
                        <ul>
                            {rewardList.threshold1.map((item) => (
                                <li key={item.id}>
                                    <div
                                        className={`gift-item ${
                                            customerRewardItem &&
                                            customerRewardItem.id === item.id
                                                ? "active"
                                                : ""
                                        } ${
                                            !isQualifiedForGift(
                                                totalPercent.firstProgressBarPercent
                                            )
                                                ? "locked"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            isQualifiedForGift(
                                                totalPercent.firstProgressBarPercent
                                            ) && setCustomerRewardItem(item);
                                        }}
                                    >
                                        <div className={`gift-image`}>
                                            <span className='checkIcon'>
                                                <CheckIcon />
                                            </span>
                                            <Image
                                                src={item.ImageLink.value}
                                                alt={item.ItemName.value}
                                                fluid='true'
                                            />
                                            {!isQualifiedForGift(
                                                totalPercent.firstProgressBarPercent
                                            ) && (
                                                <div className='lock-overlay'>
                                                    <Image
                                                        src={WhiteLock}
                                                        alt='beats pro'
                                                        fluid='true'
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className='gift-name'>
                                            {item.ItemName.value}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='gift-level'>
                        {rewardsThreshold.threshold2 - totalPrice > 0 && (
                            <label>{`Add $${toTwoDecimals(
                                rewardsThreshold.threshold2 - totalPrice
                            )} to unlock`}</label>
                        )}
                        <ul>
                            {rewardList.threshold2.map((item) => (
                                <li key={item.id}>
                                    <div
                                        className={`gift-item ${
                                            customerRewardItem &&
                                            customerRewardItem.id === item.id
                                                ? "active"
                                                : ""
                                        } ${
                                            !isQualifiedForGift(
                                                totalPercent.secondProgressBarPercent
                                            )
                                                ? "locked"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            isQualifiedForGift(
                                                totalPercent.secondProgressBarPercent
                                            ) && setCustomerRewardItem(item);
                                        }}
                                    >
                                        <div className={`gift-image`}>
                                            <span className='checkIcon'>
                                                <CheckIcon />
                                            </span>
                                            <Image
                                                src={item.ImageLink.value}
                                                alt={item.ItemName.value}
                                                fluid='true'
                                            />
                                            {!isQualifiedForGift(
                                                totalPercent.secondProgressBarPercent
                                            ) && (
                                                <div className='lock-overlay'>
                                                    <Image
                                                        src={WhiteLock}
                                                        alt='beats pro'
                                                        fluid='true'
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className='gift-name'>
                                            {item.ItemName.value}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {totalPrice < rewardsThreshold.threshold1 && (
                            <div className='level-lock-overlay'>
                                <div className='overlay-content'>
                                    <Image
                                        src={GoldenLock}
                                        alt='lock'
                                        fluild={"true"}
                                    />
                                    <div>{`Add $${toTwoDecimals(
                                        rewardsThreshold.threshold2 - totalPrice
                                    )} to unlock`}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='gift-level'>
                        {rewardsThreshold.threshold3 - totalPrice > 0 && (
                            <label>
                                {`Add $${toTwoDecimals(
                                    rewardsThreshold.threshold3 - totalPrice
                                )} to unlock`}
                            </label>
                        )}
                        <ul>
                            {rewardList.threshold3.map((item) => (
                                <li key={item.id}>
                                    <div
                                        className={`gift-item ${
                                            customerRewardItem &&
                                            customerRewardItem.id === item.id
                                                ? "active"
                                                : ""
                                        } ${
                                            !isQualifiedForGift(
                                                totalPercent.thirdProgressBarPercent
                                            )
                                                ? "locked"
                                                : ""
                                        }`}
                                        onClick={() => {
                                            isQualifiedForGift(
                                                totalPercent.thirdProgressBarPercent
                                            ) && setCustomerRewardItem(item);
                                        }}
                                    >
                                        <div className={`gift-image`}>
                                            <span className='checkIcon'>
                                                <CheckIcon />
                                            </span>
                                            <Image
                                                src={item.ImageLink.value}
                                                alt={item.ItemName.value}
                                                fluid='true'
                                            />
                                            {!isQualifiedForGift(
                                                totalPercent.thirdProgressBarPercent
                                            ) && (
                                                <div className='lock-overlay'>
                                                    <Image
                                                        src={WhiteLock}
                                                        alt='beats pro'
                                                        fluid='true'
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className='gift-name'>
                                            {item.ItemName.value}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {totalPrice < rewardsThreshold.threshold2 && (
                            <div className='level-lock-overlay'>
                                <div className='overlay-content'>
                                    <Image
                                        src={GoldenLock}
                                        alt='lock'
                                        fluild={"true"}
                                    />
                                    <div>{`Add $${toTwoDecimals(
                                        rewardsThreshold.threshold3 - totalPrice
                                    )} to unlock`}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    );

    const handleToggle = (data) => {
        console.log(data);
    };

    const getPercentageOfProgressBar = (total) => {
        if (total <= rewardsThreshold.threshold1) {
            const totalValueForBlock = rewardsThreshold.threshold1;
            const totalProgressInBlock = total - 0;
            const totalPercentage =
                (totalProgressInBlock / totalValueForBlock) * 100;
            return totalPercentage > 100 ? 100 : totalPercentage;
        } else if (total <= rewardsThreshold.threshold2) {
            const totalValueForBlock =
                rewardsThreshold.threshold2 - rewardsThreshold.threshold1;
            const totalProgressInBlock = total - rewardsThreshold.threshold1;
            const totalPercentage =
                (totalProgressInBlock / totalValueForBlock) * 100;
            return totalPercentage > 100 ? 100 : totalPercentage;
        } else {
            const totalValueForBlock =
                rewardsThreshold.threshold3 - rewardsThreshold.threshold2;
            const totalProgressInBlock = total - rewardsThreshold.threshold2;
            const totalPercentage =
                (totalProgressInBlock / totalValueForBlock) * 100;
            return totalPercentage > 100 ? 100 : totalPercentage;
        }
    };

    return (
        <div className={`gamification-block ${cartOpen ? "cart-opened" : ""}`}>
            <OverlayTrigger
                trigger='click'
                placement='top-end'
                overlay={popover}
                rootClose={true}
                show={showGamificationPopOver}
                onToggle={(val) => setShowGamificationPopOver(val)}
            >
                <span>
                    <ReactCustomizableProgressbar
                        radius={38}
                        fillColor='#ffffff'
                        progress={getPercentageOfProgressBar(
                            totalPrice
                        ).toFixed(2)}
                        strokeWidth={4}
                        strokeColor='#9B51E0'
                        strokeLinecap='round'
                        trackStrokeWidth={2}
                    >
                        <div className='indicator'>
                            <Image src={GiftBoxIcon} />
                        </div>
                        {isExploding && (
                            <ConfettiExplosion
                                onComplete={() => setIsExploding(!isExploding)}
                                particleCount={500}
                                particleSize={8}
                                duration={4000}
                                zIndex={1}
                                force={0.99}
                            />
                        )}
                    </ReactCustomizableProgressbar>
                </span>
            </OverlayTrigger>
        </div>
    );
};

export default Gamification;
