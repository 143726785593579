import { useEffect, useRef } from "react";
import "./index.scss";

const AwareButton = ({ children, href, target, className, onClick }) => {
    const buttonRef = useRef(null);
    useEffect(() => {
        buttonRef.current.onmouseenter = function(e) {
            const docStyle = document.documentElement.style;
            const parentOffset = buttonRef.current.getBoundingClientRect();
            const relx = e.pageX - parentOffset.left;
            const rely = e.pageY - parentOffset.top;

            docStyle.setProperty("--left", `${relx}px`);
            docStyle.setProperty("--top", `${rely}px`);
        };

        buttonRef.current.onmouseout = function(e) {
            const docStyle = document.documentElement.style;
            const parentOffset = buttonRef.current.getBoundingClientRect();
            const relx = e.pageX - parentOffset.left;
            const rely = e.pageY - parentOffset.top;

            docStyle.setProperty("--left", `${relx}px`);
            docStyle.setProperty("--top", `${rely}px`);
        };
    });

    return (
        <>
            {href ? (
                <a
                    className={`aware-button primary ${className}`}
                    href={href}
                    ref={buttonRef}
                    target={target ? target : "_self"}
                >
                    <span>{children}</span>
                </a>
            ) : (
                <button
                    onClick={onClick}
                    className={`aware-button primary ${className}`}
                    href={href}
                    ref={buttonRef}
                >
                    <span>{children}</span>
                </button>
            )}
        </>
    );
};

export default AwareButton;
