import React from "react";

import "./index.scss";
import { Placeholder } from "react-bootstrap";

const ProductsLoader = () => {
    return (
        <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((item) => (
                <div className='col-lg-2 col-sm-3 col-6' key={item}>
                    <div className='product-block-placeholder mb-5'>
                        <div className='image-placeholder'>
                            <Placeholder as='p' animation='glow'>
                                <Placeholder
                                    xs={12}
                                    style={{
                                        height: "120px",
                                        marginBottom: "10px"
                                    }}
                                />
                            </Placeholder>
                        </div>
                        <div className='description'>
                            <Placeholder as='p' animation='glow'>
                                <Placeholder xs={12} />
                            </Placeholder>
                            <Placeholder as='p' animation='glow'>
                                <Placeholder xs={5} />
                                <Placeholder xs={2} className='ms-2' />
                            </Placeholder>
                            <Placeholder as='p' animation='glow'>
                                <Placeholder xs={9} />
                            </Placeholder>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};
export default ProductsLoader;
