/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
    Col,
    Container,
    Form,
    Modal,
    Row,
    Spinner,
    Image
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useBetween } from "use-between";
import Layout from "../../layout";
import "./index.scss";
import useApi from "../../pages/useApi";
import { useNavigate } from "react-router-dom";
import { CheckMark, EmailIcon, PhoneIcon } from "../../components/icons";
import { toDateType } from "../../plugins";
import AwareButton from "../../components/button";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { Cart } from "../../components";
import OrderSuccessIcon from "../../assets/images/profile/order-success-icon.png";

const Checkout = () => {
    const [totalPrice, setTotalPrice] = useState(0);
    const [originalPrice, setOriginalPrice] = useState(0);
    const [date, setDate] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [deliveryTimeOpen, setDeliveryTimeOpen] = useState(null);
    const [deliveryTimeClosed, setDeliveryTimeClosed] = useState(null);
    const [defaultDelivery, setDefaultDelivery] = useState(false);

    const [note, setNote] = useState("");
    const [cartDetails, setCartDetails] = useState([]);
    const [filterDays, setFilterDays] = useState([0, 1, 2, 3, 4, 5, 6]);
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(false);
    const options = { style: "decimal", minimumFractionDigits: 2 };

    const navigate = useNavigate();

    const {
        updating,
        customerId,
        cart,
        customer,
        customerRewardItem,
        rewardsThreshold,
        cartProducts,
        getCustomer,
        setUpdating,
        getCart,
        setCartAmount,
        addOrder,
        updateCart,
        getDelivery,
        logout,
        setCartOpen
    } = useBetween(useApi);
    const allDays = [0, 1, 2, 3, 4, 5, 6];
    const filteredDays = allDays.filter((day) => filterDays.includes(day));

    const removeCart = () => {
        let formData = {};
        formData = {
            sessionId: cart.customerId,
            customerId: cart.customerId,
            products: []
        };
        updateCart(cart._id, formData);
    };

    const handleClose = () => {
        setShow(false);
        navigate("/collection/all");
        removeCart();
    };

    const handleViewOrderDetails = () => {
        setShow(false);
        navigate("/profile/orders");
        removeCart();
    };

    useEffect(() => {
        if (customerId) {
            setStatus(true);
            getCart(customerId);
            getCustomer(customerId)
                .then((res) => {
                    const zoneId = res.customer.ShippingZoneID.toLowerCase()
                        .replace("zone ", "")
                        .toUpperCase();
                    getDelivery(zoneId).then((res) => {
                        setFilterDays(res.delivery.available);
                        let time = res.cutoff.time;
                        if (res.cutoff.day === "pm" && res.cutoff.time !== 12) {
                            time = res.cutoff.time + 12;
                        }

                        if (res.cutoff.day === "am" && res.cutoff.time === 12) {
                            time = 0;
                        }
                        const minDate = moment
                            .tz("America/Los_Angeles")
                            .add(res.delivery.leadTime, "days")
                            .add(time, "hours")
                            .toDate();
                        setStartDate(minDate);
                    });
                    setStatus(false);
                })
                .catch(() => logout());
        }
        setCartOpen(false);
    }, [customerId]);

    useEffect(() => {
        if (customer.DeliveryTimeOpen) {
            setDeliveryTimeOpen(
                moment(`2023-1-1 ${customer.DeliveryTimeOpen}`).toDate()
            );
        }
        if (customer.DeliveryTimeClosed) {
            setDeliveryTimeClosed(
                moment(`2023-1-1 ${customer.DeliveryTimeClosed}`).toDate()
            );
        }
    }, [customer]);

    useEffect(() => {
        let price = 0;
        let originalPrice = 0;
        const details = [];

        if (Object.keys(cart).length) {
            cart.products.map((item) => {
                let detail = {
                    InventoryID: {
                        value: cartProducts[item.productId].InventoryID.value
                    },
                    OrderQty: {
                        value: item.quantity
                    },
                    SKUDescription: {
                        value: cartProducts[item.productId].SKUDescription.value
                    },
                    StrainDescription: {
                        value:
                            cartProducts[item.productId].StrainDescription.value
                    },
                    DefaultPrice: {
                        value: cartProducts[item.productId].DefaultPrice.value
                    },
                    Primaryproductimagefirstdisplayed: {
                        value:
                            cartProducts[item.productId]
                                .Primaryproductimagefirstdisplayed.value
                    }
                };

                details.push(detail);

                price +=
                    item.quantity *
                    cartProducts[item.productId].DefaultPrice.value;
                originalPrice +=
                    item.quantity *
                    cartProducts[item.productId].OriginalPrice.value;
            });

            setCartDetails(details);
            setTotalPrice(price);
            setOriginalPrice(originalPrice);
            setCartAmount(cart.products.length);
        }
    }, [cart, cartProducts]);

    const handleSubmit = () => {
        if (rewardsThreshold) {
            if (totalPrice > rewardsThreshold.threshold1) {
                if (!customerRewardItem) {
                    toast.error(
                        "Please select a reward item before submitting your order."
                    );
                    return false;
                } else {
                    if (
                        cartDetails.findIndex(
                            (item) =>
                                item.InventoryID.value ===
                                customerRewardItem.InventoryID.value
                        ) < 0
                    )
                        cartDetails.push({
                            InventoryID: customerRewardItem.InventoryID,
                            Primaryproductimagefirstdisplayed:
                                customerRewardItem.ImageLink,
                            OrderQty: { value: 1 },
                            SKUDescription: {
                                value: ""
                            },
                            StrainDescription: {
                                value: ""
                            },
                            DefaultPrice: {
                                value: ""
                            }
                        });
                }
            }
        }

        if (date) {
            // setUpdating(true);
            const formData = {
                note: {
                    value: note
                },
                CustomerID: {
                    value: customerId
                },
                RequestedOn: {
                    value: date
                },
                // NewDeliveryTime: {
                //     DeliveryTimeOpen: deliveryTimeOpen
                //         ? moment(deliveryTimeOpen).format("hh:mm A")
                //         : "",
                //     DeliveryTimeClosed: deliveryTimeClosed
                //         ? moment(deliveryTimeClosed).format("hh:mm A")
                //         : "",
                //     MakeDeliveryTime: defaultDelivery
                // },
                Details: cartDetails
            };
            setUpdating(true);
            addOrder(formData)
                .then(() => {
                    //setShow(true);
                    setUpdating(false);
                })
                .catch((err) => {
                    console.log("Error occored during order place", err);
                });
            setTimeout(() => {
                setShow(true);
            }, 1000);
        } else {
            toast.error("Please select a delivery date.");
        }
    };

    return (
        <Layout>
            <div className='checkout-background'>
                <Container className='checkout-container'>
                    <Row>
                        <Col
                            sm={12}
                            lg={8}
                            md={6}
                            style={{ background: "#fff" }}
                        >
                            <Form className='checkout-summary'>
                                {Object.keys(customer).length ? (
                                    <>
                                        <h4 className='mb-3'>
                                            Delivery Information
                                        </h4>
                                        <div className='check-card'>
                                            <Row>
                                                <Col md={6} xs={12}>
                                                    <Form.Group className='form-group'>
                                                        <Form.Label className='mb-2'>
                                                            Address
                                                        </Form.Label>
                                                        <div className='form-group-content'>
                                                            <p>
                                                                {
                                                                    customer.AddressLine1
                                                                }
                                                            </p>
                                                            <p>
                                                                {customer.City},{" "}
                                                                {customer.State}{" "}
                                                                {
                                                                    customer.PostalCode
                                                                }
                                                            </p>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} xs={12}>
                                                    <Form.Group className='form-group'>
                                                        <Form.Label className='mb-2'>
                                                            License Number
                                                        </Form.Label>
                                                        <div className='form-group-content'>
                                                            <p>
                                                                {
                                                                    customer[
                                                                    "LIC#1: Primary License"
                                                                    ]
                                                                }
                                                            </p>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                ) : null}
                                <h4 className='mb-4'>Schedule Delivery</h4>
                                <div className='check-card mb-4'>
                                    <Row className='date-time-picker'>
                                        <Col
                                            lg={
                                                customer.DeliveryTimeOpen &&
                                                    customer.DeliveryTimeClosed
                                                    ? "12"
                                                    : "12"
                                            }
                                            className='mb-3'
                                        >
                                            <Form.Label>
                                                Desired Delivery Date
                                            </Form.Label>
                                            <DatePicker
                                                selected={date}
                                                minDate={startDate}
                                                onChange={(date) =>
                                                    setDate(date)
                                                }
                                                filterDate={(date) =>
                                                    filteredDays.includes(
                                                        date.getDay()
                                                    )
                                                }
                                                placeholderText='Select a delivery date'
                                            />
                                            <p>
                                                Available delivery dates are
                                                displayed based on your
                                                location.{" "}
                                            </p>
                                        </Col>
                                        {/* {customer.DeliveryTimeOpen &&
                                            customer.DeliveryTimeClosed && (
                                                <Col lg={6} className='mb-3'>
                                                    <div className='d-flex'>
                                                        <div>
                                                            <Form.Label>
                                                                Intake hours
                                                                begin
                                                            </Form.Label>
                                                            <DatePicker
                                                                selected={
                                                                    deliveryTimeOpen
                                                                }
                                                                onChange={(
                                                                    date
                                                                ) => {
                                                                    setDeliveryTimeOpen(
                                                                        date
                                                                    );
                                                                    setDefaultDelivery(
                                                                        false
                                                                    );
                                                                }}
                                                                showTimeSelectOnly
                                                                showPopperArrow={
                                                                    false
                                                                }
                                                                timeIntervals={
                                                                    15
                                                                }
                                                                timeCaption='Time'
                                                                dateFormat='h:mm aa'
                                                                placeholderText='--:-- AM/PM'
                                                                isClearable
                                                            />
                                                        </div>
                                                        <div>
                                                            <Form.Label>
                                                                Intake hours
                                                                close
                                                            </Form.Label>
                                                            <DatePicker
                                                                selected={
                                                                    deliveryTimeClosed
                                                                }
                                                                onChange={(
                                                                    date
                                                                ) => {
                                                                    setDeliveryTimeClosed(
                                                                        date
                                                                    );
                                                                    setDefaultDelivery(
                                                                        false
                                                                    );
                                                                }}
                                                                showTimeSelectOnly
                                                                showPopperArrow={
                                                                    false
                                                                }
                                                                timeIntervals={
                                                                    15
                                                                }
                                                                timeCaption='Time'
                                                                dateFormat='h:mm aa'
                                                                placeholderText='--:-- AM/PM'
                                                                isClearable
                                                            />
                                                        </div>
                                                    </div>
                                                    {(moment(
                                                        deliveryTimeClosed
                                                    ).format("h:mm A") !==
                                                        customer.DeliveryTimeClosed ||
                                                        moment(
                                                            deliveryTimeOpen
                                                        ).format("h:mm A") !==
                                                            customer.DeliveryTimeOpen) && (
                                                        <Form.Group
                                                            className='mb-3 delivery-time'
                                                            controlId='defaultDeliveryDateCheckbox'
                                                        >
                                                            <Form.Check
                                                                onChange={(e) =>
                                                                    setDefaultDelivery(
                                                                        e.target
                                                                            .checked
                                                                    )
                                                                }
                                                                checked={
                                                                    defaultDelivery
                                                                }
                                                                type='checkbox'
                                                                label='Save as new default intake
                                                    hours.'
                                                                disabled={
                                                                    !deliveryTimeClosed ||
                                                                    !deliveryTimeOpen
                                                                }
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            )} */}
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group className='form-group-delivery-note'>
                                                <Form.Label>
                                                    Delivery Notes
                                                </Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    value={note}
                                                    rows={2}
                                                    maxLength='255'
                                                    onChange={(event) =>
                                                        setNote(
                                                            event.target.value
                                                        )
                                                    }
                                                    placeholder='Leave your notes here'
                                                    required
                                                    className='mb-0'
                                                />
                                                <Form.Label className='maximum-character float-right'>
                                                    {255 - note.length}{" "}
                                                    characters left
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                                <h4 className='mb-3'>
                                    Please contact your rep with any additional
                                    notes
                                </h4>
                                <div className='check-card'>
                                    {Object.keys(customer).length ? (
                                        <div className='submit-group'>
                                            <AwareButton
                                                className='btn me-3 btn-secondary second'
                                                href={`tel:${customer[
                                                    "IA:Representative Phone"
                                                    ]
                                                    }`}
                                            >
                                                <PhoneIcon />
                                                Call Rep
                                            </AwareButton>
                                            <AwareButton
                                                className='btn btn-secondary second'
                                                href={`mailto:${customer[
                                                    "IA:Representative Email"
                                                    ]
                                                    }`}
                                            >
                                                <EmailIcon />
                                                Email Rep
                                            </AwareButton>
                                        </div>
                                    ) : null}
                                </div>
                            </Form>
                        </Col>
                        <Col sm={12} lg={4} md={6} className='ps-xl-0'>
                            {/* <div
                                className={`order-summary d-flex justify-content-between flex-column ${
                                    multipleCustomers
                                        ? "hasMultipleCustomers"
                                        : ""
                                }`}
                            >
                                <div className='order-items d-flex flex-column'>
                                    <div className='d-block'>
                                        <h4>Order Summary</h4>
                                    </div>
                                    {Object.keys(cart).length &&
                                        cart.products.length > 0 && (
                                            <CartTimer showTimeOnly={false} />
                                        )}
                                    {Object.keys(cart).length
                                        ? cart.products.map((item) => (
                                              <CheckoutItem
                                                  key={item.productId}
                                                  item={item}
                                              />
                                          ))
                                        : null}
                                </div>
                                <div className='place-container'>
                                    {rewardsThreshold && (
                                        <div className='gift-item-wrapper'>
                                            <div className='d-flex align-items-center'>
                                                <Image src={GiftBoxIcon} />
                                                {totalPrice <
                                                rewardsThreshold.threshold1 ? (
                                                    <div>{`Add $${rewardsThreshold.threshold1 -
                                                        totalPrice} to unlock a reward.`}</div>
                                                ) : customerRewardItem ? (
                                                    <div>
                                                        {`Free ${customerRewardItem.ItemName.value}. `}
                                                        <span
                                                            className='text-link'
                                                            onClick={() =>
                                                                setShowGamificationPopOver(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            Change
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className='text-link'
                                                        onClick={() =>
                                                            setShowGamificationPopOver(
                                                                true
                                                            )
                                                        }
                                                    >{`Select free gift`}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <div className='order-summary-total d-flex'>
                                        <label>Total</label>
                                        <p>
                                            {originalPrice !== totalPrice ? (
                                                <span className='origin'>
                                                    $
                                                    {toTwoDecimals(
                                                        originalPrice
                                                    )}
                                                </span>
                                            ) : null}
                                            $
                                            {totalPrice.toLocaleString(
                                                "en-US",
                                                options
                                            )}
                                        </p>
                                    </div>
                                    <AwareButton
                                        className='place-order-cta primary'
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        Place Order
                                    </AwareButton>
                                </div>
                            </div> */}
                            <Cart
                                status={true}
                                setStatus={() => console.log("clicked")}
                                currentPage='checkout'
                                handlePlaceOrders={() => handleSubmit()}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                className='quick-view-modal order-confirm'
                centered
                show={show}
                size='md'
                onHide={handleClose}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <div className='product-details product-order-success'>
                                <div className='d-flex justify-content-center mb-4'>
                                    <Image
                                        src={OrderSuccessIcon}
                                        width='180px'
                                        height='auto'
                                        alt='icon'
                                    />
                                </div>
                                <h5 className='text-center mb-2 h6'>
                                    Order Submitted
                                </h5>
                                <p className='text-center mb-4'>
                                    You will receive a copy of your invoice via
                                    email within 24 hours.
                                </p>
                                <div className='description mb-3'>
                                    <label className='mb-2'>Total</label>
                                    <p>
                                        $
                                        {totalPrice.toLocaleString(
                                            "en-US",
                                            options
                                        )}
                                    </p>
                                </div>
                                <div className='description mb-3'>
                                    <label className='mb-2'>
                                        Desired Delivery Date
                                    </label>
                                    <p>{date ? toDateType(date) : null}</p>
                                </div>
                                <div className='description mb-4'>
                                    <label className='mb-2'>
                                        Delivery Note
                                    </label>
                                    <p>{note}</p>
                                </div>
                                <div className='d-flex justify-content-center mb-4'>
                                    <AwareButton
                                        onClick={() => handleClose()}
                                        className='mt-4 primary btn-primary me-3'
                                    >
                                        Continue Shopping
                                    </AwareButton>

                                    <AwareButton
                                        onClick={() => handleViewOrderDetails()}
                                        className='mt-4 primary btn-primary'
                                    >
                                        View Order Details
                                    </AwareButton>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {status ? (
                <div className='page-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}

            {/* {updating ? (
                <div className='update-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null} */}
        </Layout>
    );
};

export default Checkout;
