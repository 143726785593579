import {
    VapesIcon,
    PreRollsIcon,
    EdiblesIcon,
    ConcentratesIcon,
    BeveragesIcon,
    FlowersIcon,
    AccessoriesIcon,
    AllProductsIcon
} from "../../components/icons";
export const initialFeaturedImages = [
    {
        _id: "1",
        imagePath:
            "https://mammoth-product-images.s3.us-west-1.amazonaws.com/Images/homepage-category/All.png",
        imageLink: "/#/"
    },
    {
        _id: "2",
        imagePath:
            "https://mammoth-product-images.s3.us-west-1.amazonaws.com/Images/homepage-category/Vapes.png",
        imageLink: "/#/"
    },
    {
        _id: "3",
        imagePath:
            "https://mammoth-product-images.s3.us-west-1.amazonaws.com/Images/homepage-category/Pre+Rolls.png",
        imageLink: "/#/"
    }
];

export const productCategories = [
    {
        title: "Shop All",
        value: "Shop All",
        icon: <AllProductsIcon />
    },
    {
        title: "Vapes",
        value: "Vaporizers",
        icon: <VapesIcon />
    },
    {
        title: "Pre Rolls",
        value: "Pre Rolls",
        icon: <PreRollsIcon />
    },
    {
        title: "Edibles",
        value: "Edibles",
        icon: <EdiblesIcon />
    },
    {
        title: "Flower",
        value: "Flower",
        icon: <FlowersIcon />
    },
    {
        title: "Concentrates",
        value: "Concentrates",
        icon: <ConcentratesIcon />
    },
    {
        title: "Beverages",
        value: "Beverages",
        icon: <BeveragesIcon />
    },
    {
        title: "Accessories",
        value: "Accessories",
        icon: <AccessoriesIcon />
    }
];
