import { useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useBetween } from "use-between";
import AwareButton from "../../components/button";

import { CloseEye, Eye } from "../../components/icons";
import useApi from "../useApi";
import "./index.scss";

const Login = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const { login } = useBetween(useApi);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            username: username,
            password: password
        };

        login(formData)
            .then((res) => {
                if (res.type === "success") {
                    if (res.customer.CustomerClass === "PARENTAL") {
                        navigate("/accounts");
                    } else {
                        navigate(state?.path || "/");
                    }
                } else {
                    toast.error(
                        "Invalid login. Please reach out to your Mammoth rep for assistance."
                    );
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    return (
        <Container fluid className='login-container'>
            <Row className='vh-100'>
                <Col
                    md={5}
                    className='px-0 login-banner vh-100 d-none d-md-block position-relative'
                >
                    <Image
                        src='https://mammoth-product-images.s3.us-west-1.amazonaws.com/Images/login/login-screen.png'
                        alt='background'
                        fluid
                    />
                </Col>
                <Col className='login-form-wrapper'>
                    <Form
                        className='h-100 d-flex align-items-center'
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <Row className='login-form-inner'>
                            <Col sm={12}>
                                <h1>Welcome to Mammoth</h1>
                            </Col>
                            <Col sm={12}>
                                <p>To place an order, please log in.</p>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className='form-group'>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={username}
                                        onChange={(event) =>
                                            setUsername(event.target.value)
                                        }
                                        placeholder='Username'
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className='form-group'>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        value={password}
                                        onChange={(event) =>
                                            setPassword(event.target.value)
                                        }
                                        placeholder='Password'
                                        required
                                    />
                                    <Form.Label className='icon'>
                                        <span
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        >
                                            {showPassword ? (
                                                <Eye width='24' height='24' />
                                            ) : (
                                                <CloseEye
                                                    width='24'
                                                    height='24'
                                                />
                                            )}
                                        </span>
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                            <Col sm={12} className='text-center'>
                                <Link
                                    to={"/forgot-password"}
                                    className='btn-forgot'
                                >
                                    Forgot Password
                                </Link>
                                <Link
                                    className='btn-create'
                                    to='/request-access'
                                >
                                    Request Access
                                </Link>
                            </Col>
                            <Col sm={12}>
                                <AwareButton
                                    type='submit'
                                    className='w-100 primary btn-primary'
                                >
                                    Login
                                </AwareButton>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;
