/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Slider from "react-slick";
import {
    Container,
    Col,
    Image,
    Row,
    Spinner,
    ProgressBar,
    Button
} from "react-bootstrap";
import { useBetween } from "use-between";
import { Link, useParams } from "react-router-dom";

import CartLayout from "../../layout/CartLayout";
import ProductImg from "../../assets/images/collection/product.png";
import { LeftArrow, RightArrow } from "../../components/icons";
import useApi from "../useApi";

import "./index.scss";
import { toast } from "react-toastify";
import { roundDecimals, toDateType, toTwoDecimals } from "../../plugins";
import AwareButton from "../../components/button";

const Product = () => {
    const params = useParams();
    const productId = params.id;

    const {
        similar,
        cart,
        customerId,
        product,
        updating,
        firstLogin,
        setFirstLogin,
        setCartOpen,
        setUpdating,
        getProduct,
        addCart,
        updateCart,
        logout
    } = useBetween(useApi);
    const [exist, setExist] = useState(false);
    const [qty, setQty] = useState(1);
    const [cartQty, setCartQty] = useState(0);
    const [status, setStatus] = useState(false);
    const [orderCreated, setOrderCreated] = useState(null);

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === 0 ? true : false}
            type='button'
        >
            <LeftArrow width='24' height='24' />
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type='button'
        >
            <RightArrow width='24' height='24' />
        </button>
    );

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            }
        ]
    };

    const similarItemsSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        centerPadding: "160px",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const addToCart = (item, event, cQty) => {
        event.stopPropagation();
        setUpdating(true);

        if (Object.keys(cart).length) {
            let formData = {};
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (product) => product.productId === item._id
            );

            if (duplicatedId >= 0) {
                products[duplicatedId].quantity =
                    products[duplicatedId].quantity +
                    (cQty ? cQty : product.Casesize.value);
                formData = {
                    sessionId: cart.customerId,
                    customerId: cart.customerId,
                    products: [...products]
                };
            } else {
                formData = {
                    sessionId: cart.customerId,
                    customerId: cart.customerId,
                    products: [
                        ...cart.products,
                        {
                            productId: item._id,
                            quantity: cQty ? cQty : product.Casesize.value
                        }
                    ]
                };
            }

            updateCart(cart._id, formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        } else {
            const formData = {
                sessionId: cart.customerId,
                customerId: customerId,
                products: [
                    {
                        productId: item._id,
                        quantity: cQty ? cQty : product.Casesize.value
                    }
                ]
            };

            addCart(formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
        if (firstLogin) {
            setCartOpen(true);
            setFirstLogin(false);
            localStorage.setItem("firstLogin", false);
        }
    };

    const removeToCart = (item, event) => {
        event.stopPropagation();
        setUpdating(true);

        if (Object.keys(cart).length) {
            let formData = {};
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (product) => product.productId === item._id
            );
            if (products[duplicatedId].quantity === item.Casesize.value) {
                products.splice(duplicatedId, 1);
            } else {
                products[duplicatedId].quantity =
                    products[duplicatedId].quantity - item.Casesize.value;
            }

            formData = {
                sessionId: cart.customerId,
                customerId: cart.customerId,
                products: [...products]
            };

            updateCart(cart._id, formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
    };

    const addToSimilarCart = (item, cQty) => {
        setUpdating(true);

        if (Object.keys(cart).length) {
            let formData = {};
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (product) => product.productId === item._id
            );

            if (duplicatedId >= 0) {
                products[duplicatedId].quantity =
                    products[duplicatedId].quantity +
                    (cQty ? cQty : product.Casesize.value);
                formData = {
                    customerId: cart.customerId,
                    products: [...products]
                };
            } else {
                formData = {
                    customerId: cart.customerId,
                    products: [
                        ...cart.products,
                        {
                            productId: item._id,
                            quantity: cQty ? cQty : product.Casesize.value
                        }
                    ]
                };
            }

            updateCart(cart._id, formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        } else {
            const formData = {
                customerId: customerId,
                products: [
                    {
                        productId: item._id,
                        quantity: cQty ? cQty : product.Casesize.value
                    }
                ]
            };

            addCart(formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setStatus(false);
        getProduct(productId)
            .then((res) => {
                setStatus(true);
                if (res.orderCreated) {
                    setOrderCreated(res.orderCreated);
                }
            })
            .catch(() => logout());
    }, [productId]);

    useEffect(() => {
        if (Object.keys(cart).length) {
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (item) => item.productId === productId
            );
            if (duplicatedId >= 0) {
                setExist(true);
                setCartQty(products[duplicatedId].quantity);
            } else {
                setExist(false);
                setCartQty(0);
            }
        }
    }, [cart]);

    useEffect(() => {
        if (Object.keys(product).length) {
            setQty(product.Casesize.value);
        }
    }, [product]);

    return (
        <CartLayout>
            {Object.keys(product).length ? (
                <div className='product-container'>
                    <div className='product-details'>
                        {/* <Container className='py-3'>
                            <Breadcrumb className='mb-4'>
                                <Breadcrumb.Item href='/collection/all'>
                                    All Products
                                </Breadcrumb.Item>
                                {product.Brand.value ? (
                                    <Breadcrumb.Item
                                        href={`/collection/all?brand=${product.Brand.value}`}
                                    >
                                        {product.Brand.value}
                                    </Breadcrumb.Item>
                                ) : null}
                                {product.Parentcategory.value ? (
                                    <Breadcrumb.Item
                                        href={`/collection/all?category=${product.Parentcategory.value}`}
                                    >
                                        {product.Parentcategory.value}
                                    </Breadcrumb.Item>
                                ) : null}
                                <Breadcrumb.Item active>
                                    {product.StrainDescription.value}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Container> */}
                        <Container>
                            <Row>
                                <Col md='6' className='product-img'>
                                    <div className='main-product-image'>
                                        <Slider {...settings}>
                                            <div className='img-wrapper'>
                                                {product
                                                    .Primaryproductimagefirstdisplayed
                                                    .value ? (
                                                    <Image
                                                        src={
                                                            product
                                                                .Primaryproductimagefirstdisplayed
                                                                .value
                                                        }
                                                        alt='product'
                                                    />
                                                ) : (
                                                    <Image
                                                        src={ProductImg}
                                                        alt='product'
                                                    />
                                                )}
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className='similar-items'>
                                        <h5>Similar Products</h5>
                                        <div className='similar-wrapper'>
                                            <Slider {...similarItemsSettings}>
                                                {similar.map((item, idx) => (
                                                    <div
                                                        key={idx}
                                                        className='similar-product-item '
                                                    >
                                                        <div className='similar-cell d-flex'>
                                                            <Link
                                                                className='d-flex'
                                                                to={`/product/${item._id}`}
                                                            >
                                                                <div className='img-wrapper'>
                                                                    {item
                                                                        .Primaryproductimagefirstdisplayed
                                                                        .value ? (
                                                                        <Image
                                                                            src={
                                                                                item
                                                                                    .Primaryproductimagefirstdisplayed
                                                                                    .value
                                                                            }
                                                                            alt='product'
                                                                        />
                                                                    ) : (
                                                                        <Image
                                                                            src={
                                                                                ProductImg
                                                                            }
                                                                            alt='product'
                                                                        />
                                                                    )}
                                                                </div>
                                                            </Link>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <Link
                                                                    className='d-flex'
                                                                    to={`/product/${item._id}`}
                                                                >
                                                                    <div className='d-flex flex-column'>
                                                                        <h4>
                                                                            {
                                                                                item
                                                                                    .Buyermenuproductname
                                                                                    .value
                                                                            }
                                                                        </h4>
                                                                        <label>
                                                                            {
                                                                                item
                                                                                    .StrainDescription
                                                                                    .value
                                                                            }
                                                                            {item.Phenotype ? (
                                                                                <span
                                                                                    className='green-tg'
                                                                                    data-phenotype={
                                                                                        item.Phenotype
                                                                                    }
                                                                                >
                                                                                    <span>
                                                                                        {item.Phenotype.charAt(
                                                                                            0
                                                                                        ).toUpperCase()}
                                                                                    </span>
                                                                                </span>
                                                                            ) : null}
                                                                        </label>
                                                                        <p>
                                                                            $
                                                                            {toTwoDecimals(
                                                                                item
                                                                                    .DefaultPrice
                                                                                    .value
                                                                            )}
                                                                            {item
                                                                                .Casesize
                                                                                .value >
                                                                            1 ? (
                                                                                <span className='price-amount'>{` (${item.Casesize.value} units / case)`}</span>
                                                                            ) : null}
                                                                        </p>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </Col>
                                <Col md='6' className='ps-md-0'>
                                    <div className='product-detail-desp-box'>
                                        <div className='sub-title mb-2'>
                                            {`${product.Brand.value} | ${product.Buyermenuproductname.value}`}
                                        </div>
                                        <div className='title mb-3'>
                                            <h1>
                                                {
                                                    product.StrainDescription
                                                        .value
                                                }
                                            </h1>
                                        </div>
                                        <div className='d-flex mb-4'>
                                            {product.Phenotype ? (
                                                <span
                                                    className='tag tag-green mr-2'
                                                    data-phenotype={
                                                        product.Phenotype
                                                    }
                                                >
                                                    {product.Phenotype}
                                                </span>
                                            ) : null}

                                            {product.Displaypotency.value ===
                                            "Yes" ? (
                                                <span className='thc tag mx-2'>
                                                    {product.minTHC ===
                                                    product.maxTHC
                                                        ? `${roundDecimals(
                                                              product.THC.value
                                                          )}% `
                                                        : `${roundDecimals(
                                                              product.minTHC
                                                          )}% ~ ${roundDecimals(
                                                              product.maxTHC
                                                          )}% `}
                                                    THC
                                                </span>
                                            ) : null}
                                        </div>

                                        <div className='product-quantity-in-stock'>
                                            {/* <p className='mb-2'>
                                                14 units sold in the last 24
                                                hours.
                                            </p>
                                            <ProgressBar
                                                variant='danger'
                                                now={60}
                                                style={{
                                                    height: "5px",
                                                    borderRadius: "1px",
                                                    marginBottom: "10px"
                                                }}
                                            /> */}
                                            {/* <p className='stock'>
                                                Only 20 items left. Hurry!
                                            </p> */}
                                        </div>

                                        <div className='price'>
                                            <div className='price-info-block'>
                                                <span className=''>
                                                    <span className='price-unit'>
                                                        $
                                                        {toTwoDecimals(
                                                            product.DefaultPrice
                                                                .value
                                                        )}
                                                    </span>
                                                    <span className='price-total mx-2'>{`$${toTwoDecimals(
                                                        product.DefaultPrice
                                                            .value *
                                                            product.Casesize
                                                                .value
                                                    )} / ${
                                                        product.Casesize.value >
                                                        1
                                                            ? "Case"
                                                            : "Unit"
                                                    }`}</span>
                                                </span>
                                                {product.Casesize.value > 1 ? (
                                                    <span className='price-amount'>{`(${product.Casesize.value} units)`}</span>
                                                ) : null}
                                            </div>
                                            {orderCreated ? (
                                                <div className='last-purchase'>
                                                    Last purchased on{" "}
                                                    {toDateType(orderCreated)}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className='qty-container mb-3'>
                                            {/* <div className='d-flex text-center flex-column me-2 mb-3'>
                                                <span className='sold-case mb-1'>
                                                    Quantity (Sold by{" "}
                                                    {product.Casesize.value > 1
                                                        ? "Case"
                                                        : "Unit"}
                                                    )
                                                </span>
                                                <div className='qty-wrapper me-0 mb-2'>
                                                    <span
                                                        onClick={() =>
                                                            setQty(
                                                                qty !== 0
                                                                    ? qty -
                                                                          product
                                                                              .Casesize
                                                                              .value
                                                                    : 0
                                                            )
                                                        }
                                                        className={`cursor-pointer qty ${
                                                            qty === 0
                                                                ? "disable"
                                                                : ""
                                                        }`}
                                                    >
                                                        -
                                                    </span>
                                                    <span className='qty-value'>
                                                        {qty}
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            setQty(
                                                                qty +
                                                                    product
                                                                        .Casesize
                                                                        .value
                                                            )
                                                        }
                                                        className='cursor-pointer qty'
                                                    >
                                                        +
                                                    </span>
                                                </div>
                                            </div> */}
                                            <div className='product-action-button-wrapper mb-4'>
                                                {exist ? (
                                                    <span className='plus-minus'>
                                                        <span
                                                            className='d-flex align-items-center cursor-pointer justify-content-center control-icon'
                                                            onClick={(e) =>
                                                                removeToCart(
                                                                    product,
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            -
                                                        </span>
                                                        <span className='quantity-block'>
                                                            {cartQty}
                                                        </span>
                                                        <span
                                                            className='d-flex align-items-center cursor-pointer justify-content-center control-icon'
                                                            onClick={(e) =>
                                                                addToCart(
                                                                    product,
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            +
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <AwareButton
                                                        className='btn-atc btn-green primary'
                                                        onClick={(e) =>
                                                            addToCart(
                                                                product,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        Add to Cart
                                                    </AwareButton>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className='d-flex resources-buttons'>
                                            <div className='col'>
                                                <Button className='btn btn-outline-primary'>
                                                    Request samples
                                                </Button>
                                            </div>
                                        </div> */}

                                        <div className='desc-table'>
                                            {product.Description.value ? (
                                                <div className='description'>
                                                    <p>Product Description</p>
                                                    <label
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                product
                                                                    .Description
                                                                    .value
                                                        }}
                                                    ></label>
                                                </div>
                                            ) : null}

                                            {product.Taste.length ? (
                                                <div className='description description-limit flex-column d-flex justify-content-between'>
                                                    <p>Taste</p>
                                                    <label>
                                                        {product.Taste.join(
                                                            ", "
                                                        )}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Effects.length ? (
                                                <div className='description description-limit flex-column d-flex justify-content-between'>
                                                    <p>Effects</p>
                                                    <label>
                                                        {product.Effects.join(
                                                            ", "
                                                        )}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Terpenes.length ? (
                                                <div className='description description-limit flex-column d-flex justify-content-between'>
                                                    <p>Terpenes</p>
                                                    <label>
                                                        {product.Terpenes.join(
                                                            ", "
                                                        )}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Lineage ? (
                                                <div className='description description-limit flex-column d-flex justify-content-between'>
                                                    <p>Lineage</p>
                                                    <label>
                                                        {product.Lineage}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product?.BFLink?.value && (
                                                <div className='description'>
                                                    <p>Marketing Assets</p>
                                                    <div className='resources-buttons'>
                                                        <div className=''>
                                                            <AwareButton
                                                                className='aware-button primary btn-review btn-atc primary'
                                                                href={
                                                                    product
                                                                        ?.BFLink
                                                                        ?.value
                                                                }
                                                                target='_blank'
                                                                download={
                                                                    product
                                                                        ?.StrainDescription
                                                                        ?.value
                                                                }
                                                            >
                                                                Download Assets
                                                            </AwareButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            ) : null}
            {!status ? (
                <div className='page-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
            {updating ? (
                <div className='update-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
        </CartLayout>
    );
};

export default Product;
