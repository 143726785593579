import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { AlertIcon } from "../../components/icons";
import AwareButton from "../../components/button";
import "./index.scss";

const CartExpiredAlertModal = ({ show, handleClose, rebuildCart }) => {
    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <div className='product-added-success-modal'>
                            <div className='text-center mb-3'>
                                <div className='body-content'>
                                    <div className='icon-box d-flex justify-content-center'>
                                        <AlertIcon />
                                    </div>
                                    <h5>Cart Expired</h5>
                                    <p>
                                        Please verify stock to ensure the items
                                        in your cart are still in stock. If any
                                        items are sold out, similar replacements
                                        will be suggested.
                                    </p>
                                </div>
                                <div className='d-flex justify-content-center mb-4'>
                                    <AwareButton
                                        onClick={() => rebuildCart()}
                                        className='btn btn-lg btn-green w-100'
                                    >
                                        Verify Stock
                                    </AwareButton>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default CartExpiredAlertModal;
