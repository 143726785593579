import { useBetween } from "use-between";
import useApi from "../pages/useApi";
import { Header, Cart } from "../components";
import Gamification from "../components/gamification";
import { useLocation } from "react-router-dom";
import Footer from "../components/footer";
import { useMemo } from "react";

const Layout = ({ children }) => {
    const {
        customer,
        multipleCustomers,
        cartOpen,
        setCartOpen,
        rewardsThreshold,
        rewards
    } = useBetween(useApi);

    const location = useLocation();
    return (
        <div className='position-relative'>
            <Header cart={cartOpen} setCart={setCartOpen} fluid={false} />
            <div
                className={`d-flex cart-layout
                ${
                    customer.PastDueBalance &&
                    customer.TotalOpenBalance &&
                    customer.PastDueBalance > 0
                        ? "has-ar"
                        : ""
                }
                ${cartOpen ? "active" : ""} 
                ${multipleCustomers ? "hasMultipleCustomers" : ""} 
                ${location.pathname === "/checkout" ? "checkout-page" : ""}`}
            >
                {children}
                {location.pathname !== "/checkout" && (
                    <Cart
                        status={cartOpen}
                        setStatus={setCartOpen}
                        currentPage='all'
                    />
                )}
            </div>
            {rewards.length > 0 &&
                rewardsThreshold !== "null" &&
                rewardsThreshold !== null && (
                    <Gamification rewardsThreshold={rewardsThreshold} />
                )}
            <Footer />
        </div>
    );
};

export default Layout;
