/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "react-bootstrap";
import { useBetween } from "use-between";
import moment from "moment-timezone";
import Navbar from "../../components/navbar";
import Layout from "../../layout";
import useApi from "../useApi";

import "./index.scss";
import { useEffect } from "react";
import OrderItem from "./item";
import ProductsTabularLoader from "../../components/products-loader/ProductsTabularLoader";

const Orders = () => {
    const {
        updating,
        customer,
        customerId,
        orders,
        getOrders,
        getCustomer,
        setUpdating,
        logout
    } = useBetween(useApi);

    useEffect(() => {
        setUpdating(true);
        getCustomer(customerId);
        getOrders(customerId)
            .then(() => setUpdating(false))
            .catch(() => logout());
    }, [customerId]);

    return (
        <Layout>
            <div className='bg-gray'>
                <Container className='orders-container d-flex'>
                    <Navbar active='orders' />
                    <div className='orders-wrapper'>
                        <div className='title-wrapper d-flex align-items-center justify-content-between'>
                            <h4>Order History</h4>
                        </div>
                        <div className='order-table'>
                            <div className='table-header'>
                                <span className='table-cell row-id'>
                                    Order ID
                                </span>
                                <span className='table-cell row-date'>
                                    Order Date
                                </span>
                                <span className='table-cell row-quantity d-sm-block d-none'>
                                    Quantity
                                </span>
                                <span className='table-cell row-price d-md-block d-none'>
                                    Total Price
                                </span>
                                <span className='table-cell row-payment d-lg-block d-none'>
                                    Terms
                                </span>
                                <span className='table-cell row-status d-xl-block d-none'>
                                    Status
                                </span>
                                <span className='table-cell row-action'></span>
                            </div>
                            {}
                            {updating ? (
                                <ProductsTabularLoader />
                            ) : Object.keys(customer).length &&
                              orders.length > 0 ? (
                                orders
                                    .sort(
                                        (a, b) =>
                                            moment(
                                                b.RequestedOn.value
                                            ).toDate() -
                                            moment(a.RequestedOn.value).toDate()
                                    )
                                    .map((order, index) => (
                                        <OrderItem
                                            item={order}
                                            key={index}
                                            terms={customer.Terms}
                                        />
                                    ))
                            ) : (
                                <p className='text-center'>No Orders</p>
                            )}
                        </div>
                    </div>
                </Container>
            </div>
        </Layout>
    );
};

export default Orders;
