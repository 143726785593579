/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useBetween } from "use-between";
import AwareButton from "../../components/button";
import Navbar from "../../components/navbar";
import Layout from "../../layout";
import useApi from "../useApi";

import "./index.scss";

const Data = () => {
  const { updating, customer, customerId, getCustomer, setUpdating, logout } = useBetween(useApi);

  useEffect(() => {
    setUpdating(true);
    getCustomer(customerId)
      .then(() => setUpdating(false))
      .catch(() => logout())
  }, [customerId])

  return (
    <Layout>
      <div className="bg-gray">
        <Container className="data-container d-flex">
          <Navbar active="data" />
          <div className="data-wrapper">
            <h4>Request for your data to be deleted</h4>
            { Object.keys(customer).length ?
            <div className="submit-group mt-3">
              <AwareButton className="btn me-3 btn-secondary second" href={`tel:${customer["IA:Representative Phone"]}`}>Call Rep</AwareButton>
              <AwareButton className="btn btn-primary primary" href={`mailto:${customer["IA:Representative Email"]}`}>Email Rep</AwareButton>
            </div> : null }
          </div>
        </Container>
      </div>
      { updating ? (
        <div className="page-loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null }
    </Layout>
  )
}

export default Data;
