import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useBetween } from "use-between";

import {
    Checkout,
    Collection,
    // Dashboard,
    Login,
    Product,
    Details,
    Favorites,
    Orders,
    Order,
    Notifications,
    Password,
    Search,
    Forgot
} from "./pages";
import Data from "./pages/data";
import Delivery from "./pages/delivery";
import Newsletter from "./pages/newsletter";
import Privacy from "./pages/privacy";
import useApi from "./pages/useApi";
import Request from "./pages/request";
import AccountSwitcher from "./pages/accountSwitch";
import Landing from "./pages/landing";

const RequireAuth = ({ children }) => {
    const { userId } = useBetween(useApi);
    const location = useLocation();

    return userId ? (
        children
    ) : (
        <Navigate to='/login' replace state={{ path: location.pathname }} />
    );
};

const App = () => {
    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={
                        <RequireAuth>
                            <Landing />
                        </RequireAuth>
                    }
                />
                <Route path='/login' element={<Login />} />
                <Route path='/forgot-password' element={<Forgot />} />
                <Route path='/request-access' element={<Request />} />
                <Route path='/accounts' element={<AccountSwitcher />} />
                <Route
                    path='/collection/:brand'
                    element={
                        <RequireAuth>
                            <Collection />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/search'
                    element={
                        <RequireAuth>
                            <Search />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/product/:id'
                    element={
                        <RequireAuth>
                            <Product />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/checkout'
                    element={
                        <RequireAuth>
                            <Checkout />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/details'
                    element={
                        <RequireAuth>
                            <Details />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/favorites'
                    element={
                        <RequireAuth>
                            <Favorites />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/orders'
                    element={
                        <RequireAuth>
                            <Orders />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/orders/:id'
                    element={
                        <RequireAuth>
                            <Order />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/notifications'
                    element={
                        <RequireAuth>
                            <Notifications />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/password'
                    element={
                        <RequireAuth>
                            <Password />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/newsletter'
                    element={
                        <RequireAuth>
                            <Newsletter />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/privacy'
                    element={
                        <RequireAuth>
                            <Privacy />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/data'
                    element={
                        <RequireAuth>
                            <Data />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/profile/delivery'
                    element={
                        <RequireAuth>
                            <Delivery />
                        </RequireAuth>
                    }
                />
            </Routes>
            <ToastContainer
                position='top-right'
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
            />
        </>
    );
};

export default App;
