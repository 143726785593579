import { useNavigate } from "react-router-dom";
import { useBetween } from "use-between";
import useApi from "../../pages/useApi";

import "./index.scss";
import { useEffect } from "react";

const Navbar = ({ active }) => {
    const navigate = useNavigate();
    const { isAdmin, logout, multipleCustomers } = useBetween(useApi);

    const handleLogout = () => {
        logout().then(() => {
            navigate("/");
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className={`navbar-container ${
                multipleCustomers ? "hasMultipleCustomers" : ""
            }`}
        >
            <h3>Account</h3>
            <ul>
                <li
                    className={`${active === "details" ? "active" : ""}`}
                    onClick={() => navigate("/profile/details")}
                >
                    Details
                </li>
                <li
                    className={`${active === "password" ? "active" : ""}`}
                    onClick={() => navigate("/profile/password")}
                >
                    Password
                </li>
                {/* <li
                    className={`${active === "favorites" ? "active" : ""}`}
                    onClick={() => navigate("/profile/favorites")}
                >
                    Favorites
                </li> */}
                <li
                    className={`${active === "orders" ? "active" : ""}`}
                    onClick={() => navigate("/profile/orders")}
                >
                    Order History
                </li>
                <li
                    className={`${active === "privacy" ? "active" : ""}`}
                    onClick={() => navigate("/profile/privacy")}
                >
                    Privacy
                </li>
                <li
                    className={`${active === "data" ? "active" : ""}`}
                    onClick={() => navigate("/profile/data")}
                >
                    Data
                </li>
                {isAdmin === "true" ? (
                    <li
                        className={`${active === "newsletter" ? "active" : ""}`}
                        onClick={() => navigate("/profile/newsletter")}
                    >
                        Homepage Banner
                    </li>
                ) : null}
                {isAdmin === "true" ? (
                    <li
                        className={`${active === "delivery" ? "active" : ""}`}
                        onClick={() => navigate("/profile/delivery")}
                    >
                        Delivery Zones
                    </li>
                ) : null}
                <li className={`logout`} onClick={handleLogout}>
                    Sign Out
                </li>
            </ul>
        </div>
    );
};

export default Navbar;
