import React from "react";
import { Image, Col, Container, Navbar, Row, Stack } from "react-bootstrap";
import { RightArrow } from "../../components/icons";
import { Link, useNavigate } from "react-router-dom";
import { useBetween } from "use-between";
import useApi from "../../pages/useApi";
import "./index.scss";
import Logo from "../../assets/images/profile/logo.png";

const AccountSwitcher = () => {
    const {
        logout,
        cartOpen,
        setCustomerRewardItem,
        childCustomers,
        setCustomerId
    } = useBetween(useApi);
    const navigate = useNavigate();

    const handleLogout = (e) => {
        logout().then(() => {
            navigate("/");
        });
    };

    const changeCustomer = (id) => {
        setCustomerId(id);
        localStorage.setItem("customerId", id);
        setCustomerRewardItem(undefined);
        navigate("/");
    };

    return (
        <div className='full-body'>
            <Navbar expand='md'>
                <Container fluid={false} className='position-relative'>
                    <Row className='nav-wrapper'>
                        <Col md={4} xs={6}>
                            <Navbar.Brand href='/'>
                                <Image
                                    width='40px'
                                    src={Logo}
                                    alt='Mammoth logo'
                                    className='brand-logo'
                                />
                            </Navbar.Brand>
                        </Col>
                        <Col
                            md={4}
                            className='d-none align-items-center d-md-flex'
                        ></Col>
                        <Col
                            md={4}
                            xs={6}
                            className='d-flex align-items-center justify-content-end'
                        >
                            <Link
                                onClick={handleLogout}
                                className='mobile-menu-link logout'
                            >
                                Log Out
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
            <div className={`d-flex cart-layout ${cartOpen ? "active" : ""}`}>
                <Container>
                    <Row className='fullpage-height justify-content-md-center align-items-center'>
                        <Col md={6}>
                            <Stack gap={0} className='account-list'>
                                <h5 className='mb-4'>Select an account</h5>
                                {childCustomers &&
                                    childCustomers.length > 0 &&
                                    childCustomers.map((customer) => (
                                        <div
                                            key={customer.customerId}
                                            className='px-4 py-4 d-flex justify-content-between cursor-pointer'
                                            onClick={() =>
                                                changeCustomer(
                                                    customer.customerId
                                                )
                                            }
                                        >
                                            <div className='p-0'>
                                                {customer.accountRef}
                                                <span>
                                                    {`License #${customer.licenseNo}`}
                                                </span>
                                            </div>
                                            <div className='p-0'>
                                                <RightArrow
                                                    width='18px'
                                                    color='#808080'
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default AccountSwitcher;
