import React from "react";
import { Modal, Button } from "react-bootstrap";
import { LeftArrow } from "../../components/icons";
import "./index.scss";
import SimilarItem from "../../components/similarItem";
// import { similarProducts } from "./dummyData";

const SimilarProductsModal = ({
    show,
    handleBack,
    handleClose,
    handleDone,
    similarProducts,
    findSimilarProductId
}) => {
    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
            className='outofstockProductsModal'
        >
            <Modal.Header className='d-flex align-items-center justify-space-between'>
                <Modal.Title>
                    <Button
                        onClick={() => handleBack()}
                        variant='light'
                        className='back-btn p-0 me-1'
                    >
                        <LeftArrow width='18' height='18' />
                    </Button>
                    Similar Products
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='item-list-wrapper'>
                    {similarProducts.map((product) => (
                        <SimilarItem product={product} key={product.id} />
                    ))}
                </div>
                <div className='modal-action-bar'>
                    <Button
                        variant='dark'
                        onClick={() => handleDone(findSimilarProductId)}
                    >
                        Done
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SimilarProductsModal;
