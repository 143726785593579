/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { useBetween } from "use-between";

import Product from "../../assets/images/collection/product.png";
import useApi from "../../pages/useApi";
import { toTwoDecimals } from "../../plugins";
import { LeftArrow, RightArrow } from "../icons";

import "./index.scss";

const SimilarItem = ({ product }) => {
    const [qty, setQty] = useState(1);
    const [exist, setExist] = useState(false);
    const [cartQty, setCartQty] = useState(0);
    const { customerId, cart, setUpdating, updateCart, addCart } = useBetween(
        useApi
    );

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === 0 ? true : false}
            type='button'
        >
            <LeftArrow width='24' height='24' />
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type='button'
        >
            <RightArrow width='24' height='24' />
        </button>
    );

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     prevArrow: <SlickArrowLeft />,
    //     nextArrow: <SlickArrowRight />,

    //     responsive: [
    //         {
    //             breakpoint: 768,
    //             settings: {
    //                 arrows: false
    //             }
    //         }
    //     ]
    // };

    const addToCart = (item, event, cQty) => {
        event.stopPropagation();
        setUpdating(true);

        if (Object.keys(cart).length) {
            let formData = {};
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (product) => product.productId === item._id
            );

            if (duplicatedId >= 0) {
                products[duplicatedId].quantity =
                    products[duplicatedId].quantity +
                    (cQty ? cQty : product.Casesize.value);
                formData = {
                    customerId: cart.customerId,
                    products: [...products]
                };
            } else {
                formData = {
                    customerId: cart.customerId,
                    products: [
                        ...cart.products,
                        {
                            productId: item._id,
                            quantity: cQty ? cQty : product.Casesize.value
                        }
                    ]
                };
            }

            updateCart(cart._id, formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        } else {
            const formData = {
                customerId: customerId,
                products: [
                    {
                        productId: item._id,
                        quantity: cQty ? cQty : product.Casesize.value
                    }
                ]
            };

            addCart(formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
    };

    const removeToCart = (item, event) => {
        event.stopPropagation();
        setUpdating(true);

        if (Object.keys(cart).length) {
            let formData = {};
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (product) => product.productId === item._id
            );
            if (products[duplicatedId].quantity === item.Casesize.value) {
                products.splice(duplicatedId, 1);
            } else {
                products[duplicatedId].quantity =
                    products[duplicatedId].quantity - item.Casesize.value;
            }

            formData = {
                customerId: cart.customerId,
                products: [...products]
            };

            updateCart(cart._id, formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
    };

    useEffect(() => {
        if (Object.keys(cart).length) {
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (item) => item.productId === product._id
            );
            if (duplicatedId >= 0) {
                setExist(true);
                setCartQty(products[duplicatedId].quantity);
            } else {
                setExist(false);
                setCartQty(0);
            }
        }
    }, [cart]);

    useEffect(() => {
        if (Object.keys(product).length) {
            setQty(product.Casesize.value);
        }
    }, [product]);

    return (
        <>
            <div className='outofstock-item'>
                <div className='outofstock-product-image'>
                    <div className='img-wrapper'>
                        {product.Primaryproductimagefirstdisplayed.value ? (
                            <Image
                                src={
                                    product.Primaryproductimagefirstdisplayed
                                        .value
                                }
                                alt='product'
                            />
                        ) : (
                            <Image src={Product} alt='product' />
                        )}
                    </div>
                </div>
                <div className='outofstock-item-wrapper'>
                    <h6 className='cursor-pointer'>
                        {`${product.Brand.value} | ${product.Buyermenuproductname.value}`}
                    </h6>
                    <label>
                        {product.StrainDescription.value}
                        {product.Phenotype ? (
                            <span
                                className='green-tg'
                                data-phenotype={product.Phenotype}
                            >
                                <span>
                                    {product.Phenotype.charAt(0).toUpperCase()}
                                </span>
                            </span>
                        ) : null}
                    </label>
                    <div className='product-price-info d-inline-flex'>
                        <label className='price-per-unit'>
                            <span>
                                {product.OriginalPrice.value !== 0 &&
                                product.DefaultPrice.value !==
                                    product.OriginalPrice.value ? (
                                    <span className='origin'>
                                        $
                                        {toTwoDecimals(
                                            product.OriginalPrice.value
                                        )}
                                    </span>
                                ) : null}
                            </span>
                            <span>
                                ${toTwoDecimals(product.DefaultPrice.value)}
                            </span>
                        </label>
                        <label className='offered-price-info'>
                            {`$${toTwoDecimals(
                                product.DefaultPrice.value *
                                    product.Casesize.value
                            )} ${
                                product.Casesize.value > 1
                                    ? `/ case (${product.Casesize.value} units)`
                                    : "/ unit"
                            }`}
                        </label>
                    </div>
                    <div>
                        {exist ? (
                            <span className='plus-minus'>
                                <span
                                    className='d-flex align-items-center cursor-pointer justify-content-center control-icon'
                                    onClick={(e) => removeToCart(product, e)}
                                >
                                    -
                                </span>
                                <span className='quantity-block'>
                                    {cartQty}
                                </span>
                                <span
                                    className='d-flex align-items-center cursor-pointer justify-content-center control-icon'
                                    onClick={(e) => addToCart(product, e)}
                                >
                                    +
                                </span>
                            </span>
                        ) : (
                            <Button
                                variant='outline-dark'
                                className='btn-sm fixed-width'
                                onClick={(e) => addToCart(product, e)}
                            >
                                <span>Add</span>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SimilarItem;
