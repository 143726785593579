/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner, Accordion } from "react-bootstrap";
import AwareButton from "../../components/button";
import Navbar from "../../components/navbar";
import Layout from "../../layout";
import { useBetween } from "use-between";
import useApi from "../useApi";

import "./index.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Newsletter = () => {
    const [homeBanners, setHomeBanners] = useState({
        banners: [
            {
                imagePath: "",
                imageLink: ""
            },
            {
                imagePath: "",
                imageLink: ""
            },
            {
                imagePath: "",
                imageLink: ""
            }
        ]
    });

    const [productSpotLight, setProductSpotLight] = useState({
        imagePath: "",
        title: "Product Spotlight",
        subTitle: "Fast Acting Gummies",
        features: `Fast-acting formulation encourages onset within 15 minutes. \nInfused with 99%+ pure THCA diamonds \nSour flavors made from real fruit concentrates`,
        buttonLabel: "Shop Now",
        buttonLink: ""
    });

    const handleChange = (value, feildName, bannerIndex) => {
        homeBanners.banners[bannerIndex][feildName] = value;
        setHomeBanners({ ...homeBanners });
    };

    const handleSpotlightProductChange = (value, feildName) => {
        setProductSpotLight((prevData) => ({
            ...prevData,
            [feildName]: value
        }));
    };

    const {
        updating,
        isAdmin,
        getNewsletter,
        getSpotLightProduct,
        updateNewsletter,
        updateSpotLightProduct,
        setUpdating,
        logout
    } = useBetween(useApi);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAdmin !== "true") {
            navigate("/profile/details");
        } else {
            setUpdating(true);
            getNewsletter()
                .then((response) => {
                    if (
                        Object.keys(response.newsletter).length > 0 &&
                        response.newsletter.length > 0
                    ) {
                        setHomeBanners({
                            banners: response.newsletter[0].banners,
                            id: response.newsletter[0]._id
                        });
                    }
                    setUpdating(false);
                })
                .catch(() => logout());

            getSpotLightProduct()
                .then((response) => {
                    if (
                        Object.keys(response.spotlight).length > 0 &&
                        response.spotlight.length > 0
                    ) {
                        setProductSpotLight({
                            buttonLabel: response.spotlight[0].buttonLabel,
                            buttonLink: response.spotlight[0].buttonLink,
                            features: response.spotlight[0].features,
                            imagePath: response.spotlight[0].imagePath,
                            subTitle: response.spotlight[0].subTitle,
                            title: response.spotlight[0].title,
                            id: response.spotlight[0]._id
                        });
                    }
                    setUpdating(false);
                })
                .catch(() => logout());
        }
    }, []);

    const handleSubmit = () => {
        updateSpotLightProduct(productSpotLight).then((res) => {
            toast.success(res.message);
        });
    };

    const handleSubmitBanners = () => {
        const formData = {
            banners: homeBanners.banners,
            id: homeBanners.id,
            newType: true
        };

        updateNewsletter(formData).then((res) => {
            toast.success(res.message);
        });
    };

    return (
        <Layout>
            {isAdmin === "true" ? (
                <div className='bg-gray'>
                    <Container className='newsletter-container d-flex'>
                        <Navbar active='newsletter' />
                        <div className='newsletter-wrapper'>
                            <h3 className='mb-4'>Content Management</h3>
                            <Accordion className='mb-4' defaultActiveKey='0'>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header>
                                        Homepage Banner
                                    </Accordion.Header>
                                    <Accordion.Body className='p-0'>
                                        {homeBanners.banners.map(
                                            (banner, index) => (
                                                <div
                                                    className={`banner-form ${
                                                        index === 2
                                                            ? "noBorder"
                                                            : ""
                                                    }`}
                                                    key={`banner-${index}`}
                                                >
                                                    <h6>Banner {index + 1}</h6>
                                                    <Form.Group
                                                        as={Row}
                                                        className='form-group align-items-center'
                                                    >
                                                        <Form.Label
                                                            column
                                                            sm='2'
                                                        >
                                                            Image path
                                                        </Form.Label>
                                                        <Col sm='10'>
                                                            <Form.Control
                                                                type='text'
                                                                value={
                                                                    banner.imagePath
                                                                }
                                                                rows={3}
                                                                placeholder='Banner image path'
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        e.target
                                                                            .value,
                                                                        "imagePath",
                                                                        index
                                                                    )
                                                                }
                                                                required
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group
                                                        as={Row}
                                                        className='form-group align-items-center'
                                                    >
                                                        <Form.Label
                                                            column
                                                            sm='2'
                                                        >
                                                            Link
                                                        </Form.Label>
                                                        <Col sm='10'>
                                                            <Form.Control
                                                                type='text'
                                                                value={
                                                                    banner.imageLink
                                                                }
                                                                placeholder='Banner link'
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        e.target
                                                                            .value,
                                                                        "imageLink",
                                                                        index
                                                                    )
                                                                }
                                                                required
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            )
                                        )}
                                        <div className='d-flex justify-content-end p-3 pe-4'>
                                            <AwareButton
                                                className='btn btn-primary'
                                                onClick={() => {
                                                    handleSubmitBanners();
                                                }}
                                            >
                                                Save Banners
                                            </AwareButton>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header>
                                        Product Spotlight
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Form.Group
                                            as={Row}
                                            className='form-group align-items-center'
                                        >
                                            <Form.Label column sm='2'>
                                                Image Path
                                            </Form.Label>
                                            <Col sm='10'>
                                                <Form.Control
                                                    type='text'
                                                    value={
                                                        productSpotLight.imagePath
                                                    }
                                                    onChange={(e) =>
                                                        handleSpotlightProductChange(
                                                            e.target.value,
                                                            "imagePath"
                                                        )
                                                    }
                                                    placeholder='Spotlight product image path'
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group
                                            as={Row}
                                            className='form-group align-items-center'
                                        >
                                            <Form.Label column sm='2'>
                                                Title
                                            </Form.Label>
                                            <Col sm='10'>
                                                <Form.Control
                                                    type='text'
                                                    value={
                                                        productSpotLight.title
                                                    }
                                                    onChange={(e) =>
                                                        handleSpotlightProductChange(
                                                            e.target.value,
                                                            "title"
                                                        )
                                                    }
                                                    placeholder='Title'
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group
                                            as={Row}
                                            className='form-group align-items-center'
                                        >
                                            <Form.Label column sm='2'>
                                                Sub Title
                                            </Form.Label>
                                            <Col sm='10'>
                                                <Form.Control
                                                    type='text'
                                                    value={
                                                        productSpotLight.subTitle
                                                    }
                                                    onChange={(e) =>
                                                        handleSpotlightProductChange(
                                                            e.target.value,
                                                            "subTitle"
                                                        )
                                                    }
                                                    placeholder='Sub Title'
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group
                                            as={Row}
                                            className='form-group align-items-top'
                                        >
                                            <Form.Label column sm='2'>
                                                Features list
                                            </Form.Label>
                                            <Col sm='10'>
                                                <Form.Control
                                                    as='textarea'
                                                    value={
                                                        productSpotLight.features
                                                    }
                                                    onChange={(e) =>
                                                        handleSpotlightProductChange(
                                                            e.target.value,
                                                            "features"
                                                        )
                                                    }
                                                    rows={3}
                                                />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group
                                            as={Row}
                                            className='form-group align-items-center'
                                        >
                                            <Form.Label column sm='2'>
                                                Button Label
                                            </Form.Label>
                                            <Col sm='10'>
                                                <Form.Control
                                                    type='text'
                                                    value={
                                                        productSpotLight.buttonLabel
                                                    }
                                                    onChange={(e) =>
                                                        handleSpotlightProductChange(
                                                            e.target.value,
                                                            "buttonLabel"
                                                        )
                                                    }
                                                    placeholder='Button Label'
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group
                                            as={Row}
                                            className='form-group align-items-center'
                                        >
                                            <Form.Label column sm='2'>
                                                Button Link
                                            </Form.Label>
                                            <Col sm='10'>
                                                <Form.Control
                                                    type='text'
                                                    value={
                                                        productSpotLight.buttonLink
                                                    }
                                                    onChange={(e) =>
                                                        handleSpotlightProductChange(
                                                            e.target.value,
                                                            "buttonLink"
                                                        )
                                                    }
                                                    placeholder='Button Link'
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Row className='justify-content-end pe-3'>
                                            <AwareButton
                                                className='btn btn-primary'
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                            >
                                                Save product spotlight
                                            </AwareButton>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            {/* <Row>
                                <Col md={12}>
                                    <Form.Group className='form-group'>
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={message}
                                            placeholder='Message'
                                            onChange={(e) =>
                                                setMessage(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className='form-group'>
                                        <Form.Label>Button Text</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={label}
                                            placeholder='Button Text'
                                            onChange={(e) =>
                                                setLabel(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className='form-group'>
                                        <Form.Label>Button Link</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={link}
                                            placeholder='Button Link'
                                            onChange={(e) =>
                                                setLink(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className='form-group'>
                                        <Form.Label>Banner Link 1</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={banner1}
                                            placeholder='Banner Link 1'
                                            onChange={(e) =>
                                                setBanner1(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className='form-group'>
                                        <Form.Label>Banner Link 2</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={banner2}
                                            placeholder='Banner Link 2'
                                            onChange={(e) =>
                                                setBanner2(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className='form-group'>
                                        <Form.Label>Banner Link 3</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={banner3}
                                            placeholder='Banner Link 3'
                                            onChange={(e) =>
                                                setBanner3(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className='form-group'>
                                        <Form.Label>Banner Link 4</Form.Label>
                                        <Form.Control
                                            type='text'
                                            value={banner4}
                                            placeholder='Banner Link 4'
                                            onChange={(e) =>
                                                setBanner4(e.target.value)
                                            }
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row> */}
                            {/* <div className='submit-group'>
                                <AwareButton className='btn btn-secondary me-3'>
                                    Cancel
                                </AwareButton>
                                <AwareButton
                                    className='btn btn-primary'
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    Save
                                </AwareButton>
                            </div> */}
                        </div>
                    </Container>
                </div>
            ) : null}
            {updating ? (
                <div className='page-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
        </Layout>
    );
};

export default Newsletter;
