import { useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useBetween } from "use-between";
import AwareButton from "../../components/button";
import useApi from "../useApi";
import "./index.scss";
import { toast } from "react-toastify";

const Request = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [dispensary, setDispensary] = useState("");
    const { request } = useBetween(useApi);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            email: email,
            phone: phone,
            dispensary: dispensary
        };

        request(formData).then((res) => {
            navigate("/login");
            toast.success(res.message);
        });
    };

    return (
        <Container fluid className='register-container'>
            <Row className='vh-100'>
                <Col
                    md={5}
                    className='px-0 register-banner vh-100 d-none d-md-block position-relative'
                >
                    <Image
                        src='https://mammoth-product-images.s3.us-west-1.amazonaws.com/Images/login/login-screen.png'
                        alt='background'
                        fluid
                    />
                </Col>
                <Col className='register-form-wrapper'>
                    <Form
                        className='h-100 d-flex align-items-center'
                        onSubmit={(e) => {
                            handleSubmit(e);
                        }}
                    >
                        <Row className='register-form-inner'>
                            <Col sm={12}>
                                <h1>Request Access?</h1>
                            </Col>
                            <Col sm={12}>
                                <p>
                                    To request a new account, please fill out
                                    the form below.
                                </p>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className='form-group'>
                                    <Form.Label>Retailer Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={dispensary}
                                        onChange={(event) =>
                                            setDispensary(event.target.value)
                                        }
                                        placeholder='Retailer Name'
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className='form-group'>
                                    <Form.Label>Contact Name</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={email}
                                        onChange={(event) =>
                                            setEmail(event.target.value)
                                        }
                                        placeholder='Contact Name'
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12}>
                                <Form.Group className='form-group'>
                                    <Form.Label>Contact Email</Form.Label>
                                    <Form.Control
                                        type='text'
                                        value={phone}
                                        onChange={(event) =>
                                            setPhone(event.target.value)
                                        }
                                        placeholder='Contact Email'
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} className='text-center'>
                                <AwareButton
                                    type='submit'
                                    className='w-100 primary btn-primary'
                                >
                                    Submit
                                </AwareButton>
                                <Link className='btn-create' to='/login'>
                                    Back to Login
                                </Link>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Request;
