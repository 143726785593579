import React, { useState } from "react";
import { Modal, Image, Button, Spinner } from "react-bootstrap";
import "./index.scss";
import Product from "../../assets/images/collection/product.png";
import useApi from "../useApi";
import { useBetween } from "use-between";
import { toast } from "react-toastify";

const OutOfStockProductsModal = ({
    show,
    unavailableProducts,
    setSimilarProducts,
    handleClose,
    setShowSimilarProductsModal,
    setFindSimilarProductId,
    removeProduct
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingItem, setLoadingItem] = useState("");
    const { getSimilarProducts } = useBetween(useApi);

    const reOrderProduct = (products, phenotype) => {
        const productWithSamePhenoType = products.filter(
            (p) => p.Phenotype === phenotype.value
        );
        const productWithOutSamePhenoType = products.filter(
            (p) => p.Phenotype !== phenotype.value
        );
        const mergedProducts = [
            ...productWithSamePhenoType,
            ...productWithOutSamePhenoType
        ];
        return mergedProducts;
    };

    const getSimilarProductsData = (product) => {
        setIsLoading(true);
        setLoadingItem(product.InventoryID.value);
        getSimilarProducts(product.InventoryID.value)
            .then((res) => {
                if (res.type === "success") {
                    if (res.products && res.products.length > 0) {
                        setShowSimilarProductsModal(true);
                        setSimilarProducts(
                            reOrderProduct(res.products, product.Phenotype)
                        );
                    } else {
                        toast.success("No similar items are in stock.");
                    }
                    handleClose();
                    setIsLoading(false);
                    setLoadingItem("");
                }
            })
            .catch((err) => {
                toast.error(err.message);
                setIsLoading(false);
                setLoadingItem("");
            });
    };

    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            className='outofstockProductsModal'
            backdrop='static'
            keyboard={false}
        >
            <Modal.Header className='d-flex align-items-center justify-space-between'>
                <Modal.Title>
                    {unavailableProducts.length > 0
                        ? `${unavailableProducts.length} products are out of stock`
                        : "Out of stock products"}
                </Modal.Title>
                <span className='cursor-pointer' onClick={() => handleClose()}>
                    Close
                </span>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='item-list-wrapper pb-0'>
                    {unavailableProducts.map((product) => (
                        <div className='outofstock-item' key={product.id}>
                            <div className='outofstock-product-image'>
                                <div className='img-wrapper'>
                                    {product.Primaryproductimagefirstdisplayed
                                        .value ? (
                                        <Image
                                            src={
                                                product
                                                    .Primaryproductimagefirstdisplayed
                                                    .value
                                            }
                                            alt='product'
                                        />
                                    ) : (
                                        <Image src={Product} alt='product' />
                                    )}
                                </div>
                            </div>
                            <div className='outofstock-item-wrapper'>
                                <h6>
                                    {`${product.Brand.value} | ${product.Buyermenuproductname.value}`}{" "}
                                </h6>
                                {product.StrainDescription?.value && (
                                    <div className='title'>
                                        <span className='product-name'>
                                            {product.StrainDescription.value}
                                        </span>
                                        {product.Phenotype ? (
                                            typeof product.Phenotype ===
                                                "object" &&
                                            product.Phenotype !== null ? (
                                                <span
                                                    className='green-tg'
                                                    data-phenotype={
                                                        product.Phenotype.value
                                                    }
                                                >
                                                    <span>
                                                        {product.Phenotype.value
                                                            .charAt(0)
                                                            .toUpperCase()}
                                                    </span>
                                                </span>
                                            ) : (
                                                <span
                                                    className='green-tg'
                                                    data-phenotype={
                                                        product.Phenotype
                                                    }
                                                >
                                                    <span>
                                                        {product.Phenotype.charAt(
                                                            0
                                                        ).toUpperCase()}
                                                    </span>
                                                </span>
                                            )
                                        ) : null}
                                    </div>
                                )}
                                {/* <div className='product-price-info d-inline-flex'>
                                    <label className='price-per-unit'>
                                        {product.OriginalPrice?.value !== 0 &&
                                        product.DefaultPrice?.value !==
                                            product.OriginalPrice.value ? (
                                            <span className='origin'>
                                                $
                                                {toTwoDecimals(
                                                    product.OriginalPrice?.value
                                                )}
                                            </span>
                                        ) : null}
                                    </label>
                                    <label className='offered-price-info'>
                                        $
                                        {toTwoDecimals(
                                            product.DefaultPrice?.value
                                        )}
                                    </label>
                                </div> */}
                                <div>
                                    <Button
                                        variant='outline-dark'
                                        className='btn-sm d-flex align-items-center'
                                        onClick={() => {
                                            getSimilarProductsData(product);
                                            setFindSimilarProductId(product.id);
                                        }}
                                        disabled={isLoading}
                                    >
                                        Find Similar
                                        {isLoading &&
                                            loadingItem ===
                                                product.InventoryID.value && (
                                                <Spinner
                                                    animation='border'
                                                    size='sm'
                                                    className='ms-2'
                                                />
                                            )}
                                    </Button>
                                </div>
                            </div>
                            <div className='action-btn-wrapper'>
                                <Button
                                    size='sm'
                                    className='btn-close'
                                    aria-label='Remove'
                                    onClick={() => removeProduct(product.id)}
                                ></Button>
                            </div>
                        </div>
                    ))}
                    {unavailableProducts.length < 1 && (
                        <div className='px-3 py-5 text-center'>
                            No products!!!
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default OutOfStockProductsModal;
