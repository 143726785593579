import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar";
import Layout from "../../layout";

import "./index.scss";

const Privacy = () => {
  return (
    <Layout>
      <div className="bg-gray">
        <Container className="privacy-container d-flex">
          <Navbar active="privacy" />
          <div className="privacy-wrapper">
            <h3 className="mb-4">Privacy Policy</h3>
            <div className="row">
              <p>We value the privacy of our clients and our website viewers and we will not transfer your personal information to third parties without your consent, except under the limited conditions described under the discussion entitled "Information Sharing and Disclosure" below. If you choose to provide us with your personal information, we may transfer that information, within our company or to a third-party service provider as necessary.<br /><br /></p>
              <h5>Domain Information Collection</h5>
              <p>We may collect domain information to enable us to analyze how our visitors use this site. This data enables us to become more familiar with who visits our site, how often they visit, and what parts of the site they visit most often. This information is collected automatically and requires no action on your part.<br /><br/></p>
              <h5>Use of Cookies and Tracking User Traffic</h5>
              <p>Some pages on this site may use "cookies"—small files placed on your hard drive for identification purposes. A cookie file can contain information such as a user ID to track the pages visited, but the only personal information a cookie can contain is information you supply. These files are used for site registration and customization the next time you visit us.<br /><br /></p>
              <p>Some parts of the site may also use cookies to track user traffic patterns to determine the usefulness of our Website information to our users and to see how effective our navigational structure is in helping users reach that information. Please note that cookies cannot read data off your hard drive. Your browser may allow you to be notified when you are receiving a cookie, giving you the choice to accept it or not. If you prefer not to receive cookies while browsing our Website, you can set your browser to warn you before accepting cookies and refuse the cookie when your browser alerts you to its presence. You can also refuse all cookies by turning them off in your browser, By not accepting cookies, some pages may not fully function and you may not be able to access certain information on this site.<br /><br /></p>
              <br /><br />
              <h5>Information Sharing and Disclosure</h5>
              <p>Your personal information will not be shared outside our company without your permission, except under conditions listed below:<br /><br /></p>
              <p>Consenting to share your information to a third-party service provider working on our behalf to serve you.
                Requiring us to provide you with a product or service.
                We will also disclose your personal information, if required to do so by law, or in urgent circumstances, to protect personal safety, the public or our sites.<br /><br /></p>
              <h5>Email Marketing</h5>
              <p>We may use your personal data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us. We may use email marketing service providers to manage and send emails to you.<br /><br /></p>
              <h5>Protecting the Privacy of Children</h5>
              <p>We do not advertise, solicit or promote to anyone under the age of 21 on this website.</p>
              <br /><br />
              <h5>Links to Third Party Sites</h5>
              <p>This site may contain links to other sites. We do not share your personal information with those websites and we are not responsible for their privacy practices.<br /><br /></p>
              <h5>Changes to this Privacy Policy</h5>
              <p>We reserve the right to change, modify or update this policy at any time without notice.<br /><br /></p>
              <p>If you have questions or concerns about our Privacy Policy, please email us at the contact information on the site.<br /><br /></p>
              <p>Last updated 7/29/2022</p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export default Privacy;
