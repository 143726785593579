/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import React from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import CartLayout from "../../layout/CartLayout";
import "./index.scss";
import useApi from "../useApi";
import { useBetween } from "use-between";
import { Item } from "../../components";

function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}

const Search = () => {
    const {
        searchProducts,
        updating,
        setUpdating,
        getProductsByTitle
    } = useBetween(useApi);

    const debounceOnChange = React.useCallback(debounce(onChange, 800), []);
    function onChange(value) {
        if (value) {
            setUpdating(true);
            getProductsByTitle(value)
                .then(() => setUpdating(false))
                .catch(() => setUpdating(false));
        }
    }

    return (
        <CartLayout>
            <Container className='search-container py-4'>
                <h4 className='mb-4 h1'>Search</h4>
                <Form.Control
                    type='text'
                    onChange={(e) => debounceOnChange(e.target.value)}
                    placeholder='Search...'
                    required
                    className='w-100 mb-4'
                />

                <Row>
                    <h4 className='mb-4'>Results</h4>
                    {searchProducts.length
                        ? searchProducts.map((product) => (
                              <Col key={product._id} xl={3} sm={4} xs={6}>
                                  <Item style={true} product={product} />
                              </Col>
                          ))
                        : null}
                </Row>
            </Container>
            {updating ? (
                <div className='update-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
        </CartLayout>
    );
};

export default Search;
