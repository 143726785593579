/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "../../layout";
import { useBetween } from "use-between";
import useApi from "../useApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import Navbar from "../../components/navbar";
import "./index.scss";
import AwareButton from "../../components/button";
import { toast } from "react-toastify";
import { EditIcon, TimerIcon } from "../../components/icons";

const Delivery = () => {
  const navigate = useNavigate();
  const { updating, isAdmin, deliveries, getDeliveries, addDelivery, updateDelivery, removeDelivery, setUpdating, logout, updateCutoff, getCutoff } = useBetween(useApi);
  const [show, setShow] = useState(false);
  const [cutShow, setCutShow] = useState(false);
  const [time, setTime] = useState(12);
  const [day, setDay] = useState("pm");
  const [zone, setZone] = useState("");
  const [leadTime, setLeadTime] = useState(1);
  const [available, setAvailable] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [deliveryId, setDeliveryId] = useState("");

  const days = [
    {
      index: 0,
      title: "Sunday",
      short: "SUN"
    },
    {
      index: 1,
      title: "Monday",
      short: "MON"
    },
    {
      index: 2,
      title: "Tuesday",
      short: "TUE"
    },
    {
      index: 3,
      title: "Wednesday",
      short: "WED"
    },
    {
      index: 4,
      title: "Thursday",
      short: "THUR"
    },
    {
      index: 5,
      title: "Friday",
      short: "FRI"
    },
    {
      index: 6,
      title: "Saturday",
      short: "SAT"
    },
  ];

  const setDays = (day) => {
    let tempAvailable = [...available];

    if(tempAvailable.includes(day)) {
      tempAvailable.splice(tempAvailable.indexOf(day), 1)
    } else {
      tempAvailable.push(day)
    }

    setAvailable(tempAvailable.sort());
  }

  const handleClose = () => {
    setShow(false);
    setCutShow(false);
  }

  const handleCutoff = () => {
    const data = {
      time: time,
      day: day
    }
    updateCutoff(data).then(() => setCutShow(false));
  }

  const handleOpen = (data) => {
    if (data) {
      setDeliveryId(data._id);
      setIsNew(false);
      setZone(data.zone);
      setLeadTime(data.leadTime);
      setAvailable(data.available);
    } else {
      setDeliveryId("");
      setIsNew(true);
      setZone("");
      setLeadTime(1);
      setAvailable([]);
    }
    setShow(true);
  }

  const handleSubmit = () => {
    const formData = {
      zone: zone,
      leadTime: leadTime,
      available: available
    };
    if (isNew) {
      addDelivery(formData)
        .then((res) => {
          toast.success(res.message);
          getDeliveries();
          setShow(false);
        })
    } else {
      updateDelivery(deliveryId, formData)
        .then((res) => {
          toast.success(res.message);
          getDeliveries();
          setShow(false);
        })
    }
  }

  const handleRemove = () => {
    removeDelivery(deliveryId)
      .then((res) => {
        toast.success(res.message);
        getDeliveries();
        setShow(false);
      })
  }

  useEffect(() => {
    if (isAdmin !== "true") {
      navigate("/profile/details");
    } else {
      setUpdating(true);
      getCutoff().then((res) => {
        setTime(res.cutoff.time);
        setDay(res.cutoff.day);
      });
      getDeliveries()
        .then(() => setUpdating(false))
        .catch(() => logout())
    }
  }, [])

  return (
    <Layout>
      { isAdmin === "true"?
      <div className="bg-gray">
        <Container className="delivery-container d-flex">
          <Navbar active="delivery" />
          <div className="delivery-wrapper">
            <div className="d-flex align-items-center justify-content-between title-wrapper mb-3">
              <h3>Delivery Zone</h3>
              <AwareButton onClick={() => handleOpen()} className="btn">+ Add New Zone</AwareButton>
            </div>
            <div className="cutoff-wrapper d-flex align-items-center mb-4" onClick={() => setCutShow(true)}>
              <TimerIcon className="me-1" width="14" height="14" />
              Cut Off Time: { time }:00 { day.toUpperCase() } PST
              <EditIcon className="ms-1" width="14" height="14" />
            </div>
            <div className="zone-table">
              <div className="table-header">
                <span className="table-cell cell-zone">Zone #</span>
                <span className="table-cell cell-time">Min Lead Time (Days)</span>
                <span className="table-cell cell-available">Available Delivery</span>
                <span className="table-cell cell-edit">Edit</span>
              </div>
              { deliveries.map((data, index) => (
                <div className="table-details" key={index}>
                  <span className="table-cell cell-zone">{ data.zone }</span>
                  <span className="table-cell cell-time">{ data.leadTime }</span>
                  <span className="table-cell cell-available">{ data.available.length === 7 ? <span className="all">ALL</span> : data.available.map((i) => (
                    <span key={i}>{ days[i].short }, </span>
                  )) }</span>
                  <span className="cursor-pointer table-cell cell-edit d-flex align-items-center justify-content-center" onClick={() => {handleOpen(data)}}><EditIcon width="14" height="15" /><span className="ms-1">Edit</span></span>
                </div>
              )) }
            </div>
          </div>
        </Container>
      </div> : null }
      { updating ? (
        <div className="page-loading">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : null }
      <Modal className="quick-view-modal delivery-zone" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <h3 className="mb-3">Delivery Zone</h3>
            <Col sm={12} >
              <Form.Group className="form-group">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  type="text"
                  value={zone}
                  onChange={(event) => setZone(event.target.value)}
                  placeholder="Zone"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} >
              <Form.Group className="form-group">
                <Form.Label>Min Lead Time</Form.Label>
                <Form.Control
                  type="number"
                  value={leadTime}
                  onChange={(event) => setLeadTime(event.target.value)}
                  placeholder="Min Lead Time (Days)"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            { days.map((value, index) => (
              <Col sm={4} key={index}>
                <Form.Check
                  type="checkbox"
                  label={value.title}
                  value={value.index}
                  checked={available.includes(value.index)}
                  onChange={e => setDays(parseInt(e.target.value))}
                />
            </Col>
            )) }
          </Row>
          <div className="d-flex justify-content-center mb-4">
            <AwareButton onClick={() => handleSubmit()} className="mt-4 btn">{isNew ? 'Create' : 'Update'} Delivery Zone</AwareButton>
            { !isNew ? <AwareButton onClick={() => handleRemove()} className="ms-2 mt-4 btn btn-second">Remove</AwareButton> : null }
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="quick-view-modal delivery-zone" centered show={cutShow} onHide={handleClose}>
        <Modal.Header closeButton />
        <Modal.Body>
          <Row>
            <h3 className="mb-3">Cut Off Time</h3>
            <Col sm={8} >
              <Form.Group className="form-group">
                <Form.Select value={time} onChange={ e => setTime(e.target.value) }>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4} >
              <Form.Group className="form-group">
                <Form.Select value={day} onChange={ e => setDay(e.target.value) }>
                  <option value="am">AM</option>
                  <option value="pm">PM</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center mb-4">
            <AwareButton onClick={() => handleCutoff()} className="mt-4 btn">Update Cut Off Time</AwareButton>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default Delivery;
