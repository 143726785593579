/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, Image, Modal, Row, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { useBetween } from "use-between";

import Product from "../../assets/images/collection/product.png";
import useApi from "../../pages/useApi";
import { roundDecimals, toTwoDecimals } from "../../plugins";
import {
    HeartIcon,
    LeftArrow,
    MinusIcon,
    PlusIcon,
    RightArrow
} from "../icons";

import "./index.scss";

const Item = ({ style, product }) => {
    const [show, setShow] = useState(false);
    const [qty, setQty] = useState(1);
    const [exist, setExist] = useState(false);
    const [cartQty, setCartQty] = useState(0);
    const {
        favorite,
        firstLogin,
        customerId,
        cart,
        updating,
        setCartOpen,
        setUpdating,
        updateCart,
        addFavorite,
        updateFavorite,
        setFirstLogin,
        addCart,
        cartTime,
        extendCartTime
    } = useBetween(useApi);
    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    // const handleShow = (id, e) => {
    //     e.stopPropagation();
    //     setUpdating(true);
    //     getProduct(id).then(() => {
    //         setShow(true);
    //         setUpdating(false);
    //     });
    // };

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === 0 ? true : false}
            type='button'
        >
            <LeftArrow width='24' height='24' />
        </button>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <button
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            aria-hidden='true'
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type='button'
        >
            <RightArrow width='24' height='24' />
        </button>
    );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,

        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false
                }
            }
        ]
    };

    const addToCart = (item, event, cQty) => {
        event.stopPropagation();
        setUpdating(true);
        if (Object.keys(cart).length) {
            let formData = {};
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (product) => product.productId === item._id
            );

            if (duplicatedId >= 0) {
                products[duplicatedId].quantity =
                    products[duplicatedId].quantity +
                    (cQty ? cQty : product.Casesize.value);
                formData = {
                    sessionId: cart.customerId,
                    customerId: cart.customerId,
                    products: [...products]
                };
            } else {
                formData = {
                    sessionId: cart.customerId,
                    customerId: cart.customerId,
                    products: [
                        ...cart.products,
                        {
                            productId: item._id,
                            quantity: cQty ? cQty : product.Casesize.value
                        }
                    ]
                };
            }

            updateCart(cart._id, formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                    if (
                        cartTime < 20 * 60 &&
                        cartTime > 0 &&
                        cart.products &&
                        cart.products.length > 0
                    ) {
                        extendCartTime();
                    }
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        } else {
            const formData = {
                sessionId: customerId,
                customerId: customerId,
                products: [
                    {
                        productId: item._id,
                        quantity: cQty ? cQty : product.Casesize.value
                    }
                ]
            };

            addCart(formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
        if (firstLogin) {
            setCartOpen(true);
            setFirstLogin(false);
            localStorage.setItem("firstLogin", false);
        }
    };

    const removeToCart = (item, event) => {
        event.stopPropagation();
        setUpdating(true);

        if (Object.keys(cart).length) {
            let formData = {};
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (product) => product.productId === item._id
            );
            if (products[duplicatedId].quantity === item.Casesize.value) {
                products.splice(duplicatedId, 1);
            } else {
                products[duplicatedId].quantity =
                    products[duplicatedId].quantity - item.Casesize.value;
            }

            formData = {
                sessionId: cart.customerId,
                customerId: cart.customerId,
                products: [...products]
            };

            updateCart(cart._id, formData)
                .then(() => {
                    // toast.success("Cart Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
    };

    const addToFavorite = (item, e) => {
        e.stopPropagation();
        setUpdating(true);
        if (Object.keys(favorite).length) {
            const duplicatedId = favorite.products.findIndex(
                (product) => product === item._id
            );
            let formData = {};
            const newFavorites = favorite.products;
            if (duplicatedId >= 0) {
                newFavorites.splice(duplicatedId, 1);
                formData = {
                    customerId: cart.customerId,
                    products: newFavorites
                };
            } else {
                formData = {
                    customerId: cart.customerId,
                    products: [...favorite.products, item._id]
                };
            }

            updateFavorite(favorite._id, formData)
                .then(() => {
                    toast.success("Favorites Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        } else {
            const formData = {
                customerId: customerId,
                products: [item._id]
            };

            addFavorite(formData)
                .then(() => {
                    toast.success("Favorites Updated");
                    setUpdating(false);
                })
                .catch((err) => {
                    toast.error(err.message);
                    setUpdating(false);
                });
        }
    };

    useEffect(() => {
        if (Object.keys(cart).length) {
            const products = cart.products;
            const duplicatedId = products.findIndex(
                (item) => item.productId === product._id
            );
            if (duplicatedId >= 0) {
                setExist(true);
                setCartQty(products[duplicatedId].quantity);
            } else {
                setExist(false);
                setCartQty(0);
            }
        }
    }, [cart]);

    useEffect(() => {
        if (Object.keys(product).length) {
            setQty(product.Casesize.value);
        }
    }, [product]);

    return (
        <>
            {style ? (
                <div className='item-container'>
                    <div className='img-wrapper cursor-pointer'>
                        <div className='product-tags'>
                            {product.NewRelease.value && (
                                <Badge bg='success new'>New</Badge>
                            )}
                            {product.BuyerPortalFastMover.value && (
                                <Badge bg='success fast-mover'>
                                    Fast Mover
                                </Badge>
                            )}
                            {product.BuyerPortalLimitedSupply.value && (
                                <Badge bg='success limited-supply'>
                                    Limited Supply
                                </Badge>
                            )}
                        </div>

                        {product.Primaryproductimagefirstdisplayed.value ? (
                            <Image
                                src={
                                    product.Primaryproductimagefirstdisplayed
                                        .value
                                }
                                alt='product'
                            />
                        ) : (
                            <Image src={Product} alt='product' />
                        )}
                        <div
                            className='quick-actions cursor-pointer'
                            onClick={() => navigate(`/product/${product._id}`)}
                        >
                            {/* <Button
                                className={`${
                                    Object.keys(favorite).length &&
                                    favorite.products.includes(product._id)
                                        ? "active"
                                        : ""
                                }`}
                                onClick={(e) => {
                                    addToFavorite(product, e);
                                }}
                            >
                                <HeartIcon width='10' height='8' />
                            </Button> */}
                        </div>
                        {product.Displaypotency.value === "Yes" ? (
                            <span className='thc-wrap'>
                                <span>
                                    {product.minTHC === product.maxTHC
                                        ? `${roundDecimals(product.THC.value)}%`
                                        : `${roundDecimals(
                                              product.minTHC
                                          )}% ~ ${roundDecimals(
                                              product.maxTHC
                                          )}%`}{" "}
                                    THC
                                </span>
                            </span>
                        ) : null}
                    </div>
                    <div className='details-wrapper'>
                        <Link
                            className='d-flex flex-column item-details'
                            to={`/product/${product._id}`}
                        >
                            <h4>
                                {`${product.Brand.value} | ${product.Buyermenuproductname.value}`}
                            </h4>
                            <div className='title'>
                                {product.StrainDescription.value}
                                {product.Phenotype ? (
                                    <span
                                        className='green-tg'
                                        data-phenotype={product.Phenotype}
                                    >
                                        <span>
                                            {product.Phenotype.charAt(
                                                0
                                            ).toUpperCase()}
                                        </span>
                                    </span>
                                ) : null}
                            </div>
                            <p className='item-price'>
                                <span>
                                    {product.OriginalPrice.value !== 0 &&
                                    product.DefaultPrice.value !==
                                        product.OriginalPrice.value ? (
                                        <span className='origin'>
                                            $
                                            {toTwoDecimals(
                                                product.OriginalPrice.value
                                            )}
                                        </span>
                                    ) : null}
                                </span>
                                <span>
                                    ${toTwoDecimals(product.DefaultPrice.value)}
                                </span>
                            </p>
                            <div className='pack-price'>{`$${toTwoDecimals(
                                product.DefaultPrice.value *
                                    product.Casesize.value
                            )} ${
                                product.Casesize.value > 1
                                    ? `/ case (${product.Casesize.value} units)`
                                    : "/ unit"
                            }`}</div>
                        </Link>
                        <div className='btn-container'>
                            {exist ? (
                                <span className='plus-minus'>
                                    <span
                                        className='d-flex align-items-center cursor-pointer justify-content-center control-icon'
                                        onClick={(e) =>
                                            removeToCart(product, e)
                                        }
                                    >
                                        -
                                    </span>
                                    <span className='quantity-block'>
                                        {cartQty}
                                    </span>
                                    <span
                                        className='d-flex align-items-center cursor-pointer justify-content-center control-icon'
                                        onClick={(e) => addToCart(product, e)}
                                    >
                                        +
                                    </span>
                                </span>
                            ) : (
                                <Button
                                    className='btn-atc'
                                    variant='primary'
                                    onClick={(e) => addToCart(product, e)}
                                >
                                    <span>Add</span>
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className='table-details cursor-pointer'
                    onClick={() => navigate(`/product/${product._id}`)}
                >
                    <span className='table-cell title-img d-flex align-items-center'>
                        {product.Primaryproductimagefirstdisplayed.value ? (
                            <Image
                                className='me-3 d-sm-block d-none'
                                src={
                                    product.Primaryproductimagefirstdisplayed
                                        .value
                                }
                                alt='product'
                            />
                        ) : (
                            <Image
                                className='me-3 d-sm-block d-none'
                                src={Product}
                                alt='product'
                            />
                        )}
                        <span className='d-flex flex-column text-start'>
                            <span className='product-title mb-1'>
                                {`${product.Brand.value} | ${product.Buyermenuproductname.value}`}
                            </span>
                            <span className='product-info'>
                                {product.StrainDescription.value}
                                {product.Phenotype ? (
                                    <span
                                        className='green-tg'
                                        data-phenotype={product.Phenotype}
                                    >
                                        <span>
                                            {product.Phenotype.charAt(
                                                0
                                            ).toUpperCase()}
                                        </span>
                                    </span>
                                ) : null}
                            </span>
                        </span>
                    </span>
                    <span className='table-cell potency d-md-block d-none'>
                        {product.Displaypotency.value === "Yes" ? (
                            <>
                                {product.minTHC === product.maxTHC
                                    ? `${roundDecimals(product.THC.value)}%`
                                    : `${roundDecimals(
                                          product.minTHC
                                      )}% ~ ${roundDecimals(product.maxTHC)}%`}
                            </>
                        ) : null}
                    </span>
                    <span className='table-cell d-lg-block d-none'>
                        {product.OriginalPrice.value !== 0 &&
                        product.DefaultPrice.value !==
                            product.OriginalPrice.value ? (
                            <span className='origin'>
                                ${toTwoDecimals(product.OriginalPrice.value)}
                            </span>
                        ) : null}
                        <span>
                            ${toTwoDecimals(product.DefaultPrice.value)}
                        </span>
                    </span>
                    <span className='table-cell size d-xl-block d-none'>
                        {product.Casesize.value}
                    </span>
                    <span className='table-cell'>
                        $
                        {toTwoDecimals(
                            product.DefaultPrice.value * product.Casesize.value
                        )}
                    </span>
                    <span className='table-cell add-cta me-0'>
                        {exist ? (
                            <span className='d-flex justify-content-between align-items-center w-100'>
                                <span
                                    className='cart-action-btn cursor-pointer'
                                    onClick={(e) => removeToCart(product, e)}
                                >
                                    <MinusIcon width='20' height='20' />
                                </span>
                                <span>{cartQty}</span>
                                <span
                                    className='cart-action-btn cursor-pointer'
                                    onClick={(e) => addToCart(product, e)}
                                >
                                    <PlusIcon width='20' height='20' />
                                </span>
                            </span>
                        ) : (
                            <span
                                className='cart-action-btn cursor-pointer'
                                onClick={(e) => addToCart(product, e)}
                            >
                                <PlusIcon width='20' height='20' />
                            </span>
                        )}
                    </span>
                </div>
            )}
            <Modal
                className='quick-view-modal'
                centered
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12}>
                            <div className='product-details'>
                                <Row className='mb-4'>
                                    <Col className='product-img' md={5}>
                                        <Slider {...settings}>
                                            <div className='img-wrapper'>
                                                {product
                                                    .Primaryproductimagefirstdisplayed
                                                    .value ? (
                                                    <Image
                                                        src={
                                                            product
                                                                .Primaryproductimagefirstdisplayed
                                                                .value
                                                        }
                                                        alt='product'
                                                    />
                                                ) : (
                                                    <Image
                                                        src={Product}
                                                        alt='product'
                                                    />
                                                )}
                                            </div>
                                        </Slider>
                                    </Col>
                                    <Col md={7} className='p-4'>
                                        <div className='d-flex mb-2'>
                                            <span className='tag'>
                                                {
                                                    product.StrainDescription
                                                        .value
                                                }
                                            </span>
                                            {product.Phenotype ? (
                                                <span
                                                    className='tag tag-green mx-2'
                                                    data-phenotype={
                                                        product.Phenotype
                                                    }
                                                >
                                                    {product.Phenotype}
                                                </span>
                                            ) : null}
                                        </div>
                                        <div className='title mb-2'>
                                            <h1>
                                                {
                                                    product.Buyermenuproductname
                                                        .value
                                                }
                                            </h1>
                                            {/* <h2>{ product.MenuSubcategory.value }</h2> */}
                                        </div>
                                        {product.Displaypotency.value ===
                                        "Yes" ? (
                                            <div className='thc mb-2'>
                                                <p className='mb-1'>
                                                    THC Percentage:
                                                </p>
                                                <h6>
                                                    {product.minTHC ===
                                                    product.maxTHC
                                                        ? `${roundDecimals(
                                                              product.THC.value
                                                          )}%`
                                                        : `${roundDecimals(
                                                              product.minTHC
                                                          )}% ~ ${roundDecimals(
                                                              product.maxTHC
                                                          )}%`}
                                                </h6>
                                            </div>
                                        ) : null}
                                        <div className='price d-flex flex-column mb-3'>
                                            <span className='mb-1'>
                                                <span className='price-unit'>
                                                    $
                                                    {toTwoDecimals(
                                                        product.DefaultPrice
                                                            .value
                                                    )}
                                                </span>
                                                <span className='price-total mx-2'>{`$${toTwoDecimals(
                                                    product.DefaultPrice.value *
                                                        product.Casesize.value
                                                )} / case`}</span>
                                            </span>
                                            {product.Casesize.value > 1 ? (
                                                <span className='price-amount'>{`${product.Casesize.value} units / case`}</span>
                                            ) : null}
                                        </div>
                                        <div className='qty-container d-flex'>
                                            <div className='d-flex text-center flex-column me-2'>
                                                <div className='qty-wrapper me-0 mb-2'>
                                                    <span
                                                        onClick={() =>
                                                            setQty(
                                                                qty !== 0
                                                                    ? qty -
                                                                          product
                                                                              .Casesize
                                                                              .value
                                                                    : 0
                                                            )
                                                        }
                                                        className={`cursor-pointer qty ${
                                                            qty === 0
                                                                ? "disable"
                                                                : ""
                                                        }`}
                                                    >
                                                        -
                                                    </span>
                                                    <span className='qty-value'>
                                                        {qty}
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            setQty(
                                                                qty +
                                                                    product
                                                                        .Casesize
                                                                        .value
                                                            )
                                                        }
                                                        className='cursor-pointer qty'
                                                    >
                                                        +
                                                    </span>
                                                </div>
                                                <span className='sold-case'>
                                                    Sold by{" "}
                                                    {product.Casesize.value > 1
                                                        ? "Case"
                                                        : "Unit"}
                                                </span>
                                            </div>
                                            <Button
                                                disabled={updating}
                                                variant='primary'
                                                className='btn-atc'
                                                onClick={(e) =>
                                                    addToCart(product, e, qty)
                                                }
                                            >
                                                {updating
                                                    ? "Adding..."
                                                    : "Add to Cart"}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <div className='desc-table'>
                                            {product.Description.value ? (
                                                <div className='description'>
                                                    <p className='mb-3'>
                                                        Description
                                                    </p>
                                                    <label
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                product
                                                                    .Description
                                                                    .value
                                                        }}
                                                    ></label>
                                                </div>
                                            ) : null}
                                            {product.Displaypotency.value ===
                                            "Yes" ? (
                                                <div className='description description-limit d-flex justify-content-between'>
                                                    <p>THC Percentage</p>
                                                    <label>
                                                        {product.minTHC ===
                                                        product.maxTHC
                                                            ? `${roundDecimals(
                                                                  product.THC
                                                                      .value
                                                              )}%`
                                                            : `${roundDecimals(
                                                                  product.minTHC
                                                              )}% ~ ${roundDecimals(
                                                                  product.maxTHC
                                                              )}%`}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Phenotype ? (
                                                <div className='description description-limit d-flex justify-content-between'>
                                                    <p>Phenotype</p>
                                                    <label>
                                                        {product.Phenotype}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Taste.length ? (
                                                <div className='description description-limit d-flex justify-content-between'>
                                                    <p>Taste</p>
                                                    <label>
                                                        {product.Taste.join(
                                                            ", "
                                                        )}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Effects.length ? (
                                                <div className='description description-limit d-flex justify-content-between'>
                                                    <p>Effects</p>
                                                    <label>
                                                        {product.Effects.join(
                                                            ", "
                                                        )}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Terpenes.length ? (
                                                <div className='description description-limit d-flex justify-content-between'>
                                                    <p>Terpenes</p>
                                                    <label>
                                                        {product.Terpenes.join(
                                                            ", "
                                                        )}
                                                    </label>
                                                </div>
                                            ) : null}
                                            {product.Lineage ? (
                                                <div className='description description-limit d-flex justify-content-between'>
                                                    <p>Lineage</p>
                                                    <label>
                                                        {product.Lineage}
                                                    </label>
                                                </div>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Item;
