import React, { useState, useEffect } from "react";
import { ClockTimerIcon, ClockTimerExpiredIcon, InfoIcon } from "../icons";
import { Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { useBetween } from "use-between";
import "./index.scss";
import useApi from "../../pages/useApi";
import CartExpiredAlertWithExtendTImeModal from "../cart/CartExpiredAlertWithExtendTImeModal";

function CartTimer({ showTimeOnly, rebuildCart, setCartTimeExpired }) {
    const {
        cartTime,
        extendCartTime,
        dontShowTimeExtendAlert,
        setDontShowTimeExtendAlert
    } = useBetween(useApi);
    const [time, setTime] = useState(cartTime || 0);
    const [isLoading, setIsLoading] = useState(false);

    const good = 15 * 60;
    const bad = 5 * 60;

    function secondsToDigitalClock(seconds) {
        // const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;

        // Format hours, minutes, and seconds to ensure they have two digits
        // const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const getColor = (seconds) => {
        if (seconds > good) {
            return "green";
        } else if (seconds > bad) {
            return "orange";
        } else {
            return "red";
        }
    };

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            if (time > 0) {
                setTime((prevTime) => prevTime - 1);
            } else {
                clearInterval(countdownInterval);
                if (!showTimeOnly) {
                    setCartTimeExpired(true);
                }
            }
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [time]);

    const extendSomeTime = () => {
        setIsLoading(true);
        extendCartTime()
            .catch((err) => {
                console.log("extendSomeTime error", err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setTime(cartTime);
    }, [cartTime]);

    return (
        <>
            {showTimeOnly ? (
                <span className='me-2 timmer' style={{ color: getColor(time) }}>
                    {secondsToDigitalClock(time)}
                </span>
            ) : time > 0 ? (
                <div className='cart-timmer d-inline-flex w-100 align-items-center'>
                    <ClockTimerIcon className='me-2' />
                    <span className='me-2'>Inventory reserved for</span>
                    <span
                        className='me-2 timmer'
                        style={{ color: getColor(time) }}
                    >
                        {secondsToDigitalClock(time)}
                    </span>
                    {time < 20 * 60 && (
                        <Button
                            className='add-time-cart-btn'
                            size='sm'
                            variant='outline-secondary'
                            onClick={() => extendSomeTime()}
                            disabled={isLoading}
                        >
                            + 10 mins
                            {isLoading && (
                                <Spinner size='sm' className='ms-1' />
                            )}
                        </Button>
                    )}

                    <CartExpiredAlertWithExtendTImeModal
                        show={
                            !dontShowTimeExtendAlert && time < 10 * 60
                                ? true
                                : false
                        }
                        extendCartTime={() => extendSomeTime()}
                        handleClose={() => {
                            setDontShowTimeExtendAlert(true);
                        }}
                        isLoading={isLoading}
                    />

                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id={`tooltip-info`}>
                                After 30 minutes, the reserved items in your
                                cart will be released back to general inventory.
                            </Tooltip>
                        }
                    >
                        <span className='cursor-pointer'>
                            <InfoIcon />
                        </span>
                    </OverlayTrigger>
                </div>
            ) : (
                <div className='cart-timmer cart-timmer-expired d-inline-flex w-100 align-items-center'>
                    <ClockTimerExpiredIcon className='me-2' />
                    <span className='me-2'>Inventory expired.</span>
                    <span className='me-2'>
                        <Button
                            className='rebuild-cart-btn'
                            size='sm'
                            variant='success'
                            onClick={() => rebuildCart()}
                        >
                            Rebuild Cart
                        </Button>
                    </span>
                </div>
            )}
        </>
    );
}

export default CartTimer;
