/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RightIcon } from "../../components/icons";
import { toDateType, toTwoDecimals } from "../../plugins";

const OrderItem = ({ item, terms }) => {
    const [totalQty, setTotalQty] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        let orderQty = 0;
        let orderAmount = 0;
        item.Details.map((data) => {
            orderQty += data.OrderQty.value;
            orderAmount += data.OrderQty.value * data.DefaultPrice.value;
        });

        setTotalQty(orderQty);
        setTotalAmount(orderAmount);
    }, [item]);

    return (
        <div
            className='table-details'
            onClick={() => navigate("/profile/orders/" + item._id)}
        >
            <span className='table-cell row-id'>
                {item.synced ? item.OrderNbr.value : "Not available"}
            </span>
            <span className='table-cell row-date'>
                {toDateType(item.RequestedOn.value)}
            </span>
            <span className='table-cell row-quantity d-sm-block d-none'>
                {totalQty} Items
            </span>
            <span className='table-cell row-price d-md-block d-none'>
                ${toTwoDecimals(totalAmount)}
            </span>
            <span className='table-cell row-payment d-lg-block d-none'>
                {terms}
            </span>
            <span
                className='table-cell row-status d-xl-block d-none'
                data-status={item.Status.value.toLowerCase()}
            >
                {item.Status.value.replace("On Hold", "Pending")}
            </span>
            <span className='table-cell row-action'>
                <RightIcon width='24' height='24' />
            </span>
        </div>
    );
};

export default OrderItem;
