import React from "react";

import "./index.scss";
import { Placeholder } from "react-bootstrap";

const ProductsTabularLoader = () => {
    return (
        <>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                <div
                    key={item}
                    className='row mb-3  align-items-center justify-content-between'
                >
                    <span className='col-8'>
                        <span className='row align-items-center'>
                            <span className='col-2'>
                                <Placeholder as='p' animation='glow'>
                                    <Placeholder
                                        xs={12}
                                        style={{
                                            height: "60px"
                                        }}
                                    />
                                </Placeholder>
                            </span>
                            <span className='col-4'>
                                <Placeholder as='p' animation='glow'>
                                    <Placeholder xs={12} />
                                </Placeholder>
                                <Placeholder as='p' animation='glow'>
                                    <Placeholder xs={8} />
                                </Placeholder>
                            </span>
                        </span>
                    </span>
                    <span className='col-3'>
                        <Placeholder as='p' animation='glow'>
                            <Placeholder xs={12} />
                        </Placeholder>
                        <Placeholder as='p' animation='glow'>
                            <Placeholder xs={8} />
                            <Placeholder
                                xs={2}
                                style={{
                                    marginLeft: "5px"
                                }}
                            />
                        </Placeholder>
                    </span>
                </div>
            ))}
        </>
    );
};
export default ProductsTabularLoader;
