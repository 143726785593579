import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { CheckMark } from "../../components/icons";
import AwareButton from "../../components/button";
import "./index.scss";
import { useBetween } from "use-between";
import useApi from "../useApi";
import { useNavigate } from "react-router-dom";

const SuccessModal = ({ show, handleClose }) => {
    const { cartOpen, setCartOpen } = useBetween(useApi);
    const navigate = useNavigate();
    return (
        <Modal
            centered
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
        >
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <div className='product-added-success-modal'>
                            <div className='text-center mb-3'>
                                <div className='body-content'>
                                    <div className='icon-box d-flex justify-content-center'>
                                        <CheckMark />
                                    </div>
                                    <h5>Stock Verified</h5>
                                    <p>
                                        All items are in stock and have been
                                        added to your cart.
                                    </p>
                                </div>
                                <div className='d-flex justify-content-center mb-4'>
                                    <AwareButton
                                        onClick={() => {
                                            handleClose();
                                            setCartOpen(!cartOpen);
                                            navigate(`/collection/all`);
                                        }}
                                        className='btn btn-lg btn-dark w-100'
                                    >
                                        View Cart
                                    </AwareButton>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModal;
