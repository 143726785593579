import { Cart, Header } from "../components";
import { useBetween } from "use-between";
import useApi from "../pages/useApi";
import Gamification from "../components/gamification";
import Footer from "../components/footer";

const CartLayout = ({ children }) => {
    const {
        customer,
        cartOpen,
        setCartOpen,
        multipleCustomers,
        rewardsThreshold,
        rewards
    } = useBetween(useApi);

    return (
        <div className='position-relative'>
            <Header fluid={false} cart={cartOpen} setCart={setCartOpen} />
            <div
                className={`d-flex cart-layout  
                ${
                    customer.PastDueBalance &&
                    customer.TotalOpenBalance &&
                    customer.PastDueBalance > 0
                        ? "has-ar"
                        : ""
                }
                ${cartOpen ? "active" : ""} 
                ${multipleCustomers ? "hasMultipleCustomers" : ""}`}
            >
                {children}
                <Cart
                    status={cartOpen}
                    setStatus={setCartOpen}
                    currentPage='all'
                />
            </div>

            {rewards.length > 0 &&
                rewardsThreshold !== "null" &&
                rewardsThreshold !== null && (
                    <Gamification rewardsThreshold={rewardsThreshold} />
                )}
            <Footer />
        </div>
    );
};

export default CartLayout;
