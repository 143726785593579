export const roundDecimals = (num) => {
    return Math.round(num * 100) / 100;
};

export const toDateType = (d) => {
    const date = new Date(d);
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
};

export const toDate = (d) => {
    const isoDate = new Date(d).toISOString();
    const yearMonthDay = isoDate.substr(0, 10).split("-");
    const formattedDate = yearMonthDay.join("");
    return formattedDate;
};

export const toTwoDecimals = (num) => {
    const options = { style: "decimal", minimumFractionDigits: 2 };
    return num ? num.toLocaleString("en-US", options) : "0.00";
};

export const toFormattedNumbers = (num) => {
    const options = { style: "decimal", minimumFractionDigits: 0 };
    return num ? num.toLocaleString("en-US", options) : "0.00";
};

export const formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
};

export const kFormatter = (num) => {
    return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
};
