/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Col,
    Container,
    Form,
    Image,
    Row,
    Spinner
} from "react-bootstrap";
import Navbar from "../../components/navbar";
import Layout from "../../layout";
import { useBetween } from "use-between";

import "./index.scss";
import { useEffect } from "react";
import useApi from "../useApi";
import { formatPhoneNumber } from "../../plugins";

const Details = () => {
    const {
        updating,
        customer,
        customerId,
        getCustomer,
        setUpdating,
        logout
    } = useBetween(useApi);

    useEffect(() => {
        setUpdating(true);
        getCustomer(customerId)
            .then(() => setUpdating(false))
            .catch(() => logout());
    }, [customerId]);

    return (
        <Layout>
            <div className='bg-gray'>
                <Container className='details-container d-flex'>
                    <Navbar active='details' />
                    {Object.keys(customer).length ? (
                        <div className='details-wrapper'>
                            <div className='details-rep'>
                                <label className='mb-3'>Your Rep</label>
                                <div className='d-flex justify-content-between align-items-end flex-wrap'>
                                    <div className='d-flex align-items-center'>
                                        <div className='avatar me-4'>
                                            <Image
                                                src={`https://mammoth-product-images.s3.us-west-1.amazonaws.com/Rep+Headshots/${customer[
                                                    "IA:Representative"
                                                ].replace(" ", "+")}.jpg`}
                                                alt='avatar'
                                            />
                                        </div>
                                        <div className='info'>
                                            <h5 className='mb-2'>
                                                {customer["IA:Representative"]}
                                            </h5>
                                            <p>
                                                {
                                                    customer[
                                                        "IA:Representative Email"
                                                    ]
                                                }
                                            </p>
                                            <p>
                                                {formatPhoneNumber(
                                                    customer[
                                                        "IA:Representative Phone"
                                                    ]
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='cta-group d-flex'>
                                        <Button
                                            className='me-3'
                                            variant='second'
                                            href={`tel:${
                                                customer[
                                                    "IA:Representative Phone"
                                                ]
                                            }`}
                                        >
                                            Call
                                        </Button>
                                        <Button
                                            variant='second'
                                            href={`mailto:${
                                                customer[
                                                    "IA:Representative Email"
                                                ]
                                            }`}
                                        >
                                            Email
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>
                                                Business Name
                                            </Form.Label>
                                            <Form.Control
                                                type='text'
                                                value={customer["CustomerName"]}
                                                placeholder='Business Name'
                                                required
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>Address</Form.Label>
                                            <div className='address-info'>
                                                <p>{customer.AddressLine1}</p>
                                                <p>
                                                    {customer.City},{" "}
                                                    {customer.State}{" "}
                                                    {customer.PostalCode}
                                                </p>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>
                                                License Number
                                            </Form.Label>
                                            <div className='address-info'>
                                                <p>
                                                    {
                                                        customer[
                                                            "LIC#1: Primary License"
                                                        ]
                                                    }
                                                </p>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='form-group'>
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control
                                                type='text'
                                                value={formatPhoneNumber(
                                                    customer.Phone1
                                                )}
                                                placeholder='Phone'
                                                required
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <p className='text-center update-info'>
                                        <span>
                                            To update your profile, pleae reach
                                            out to your account rep.
                                        </span>
                                    </p>
                                </Row>
                            </Form>
                        </div>
                    ) : null}
                </Container>
            </div>
            {updating ? (
                <div className='page-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
        </Layout>
    );
};

export default Details;
