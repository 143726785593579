import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
    InputGroup,
    Button,
    Form,
    ListGroup,
    Popover,
    OverlayTrigger,
    Spinner
} from "react-bootstrap";
import { SearchIcon } from "../icons";
import "./index.scss";
import { useBetween } from "use-between";
import useApi from "../../pages/useApi";
import Item from "../item";
import { Link } from "react-router-dom";

function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}

const SearchBar = () => {
    const [isSearching, setIsSearching] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const {
        searchProducts,
        setSearchProducts,
        searchText,
        setSearchText,
        getProductsByTitle,
        getProductsByAvailability
    } = useBetween(useApi);

    const debounceOnChange = React.useCallback(debounce(onChange, 800), []);

    function onChange(value) {
        if (value) {
            setIsSearching(true);
            getProductsByTitle(value)
                .then(() => {
                    setIsSearching(false);
                    setShowDropdown(true);
                })
                .catch(() => setIsSearching(false));
        }
    }

    useEffect(() => {
        getProductsByAvailability("new-release").then((res) => {
            setSearchProducts(res.products);
        });
    }, []);

    return (
        <Dropdown
            className='search-form-bar-wrapper'
            onToggle={(val) => setShowDropdown(val)}
            show={showDropdown}
        >
            <Dropdown.Toggle
                className='p-0'
                role='menubar'
                id='dropdown-custom-components'
                as='div'
            >
                <InputGroup className='search-form-bar'>
                    <Form.Control
                        type='text'
                        value={searchText}
                        placeholder='What can we help you find? '
                        aria-label='What can we help you find? '
                        onChange={(e) => {
                            e.stopPropagation();
                            debounceOnChange(e.target.value);
                            setSearchText(e.target.value);
                        }}
                    />
                    <Button variant='outline-secondary' id='button-addon2'>
                        {isSearching ? (
                            <Spinner animation='border' size='sm' />
                        ) : (
                            <SearchIcon width='24' height='24' />
                        )}
                    </Button>
                </InputGroup>
            </Dropdown.Toggle>

            <Dropdown.Menu role='menu'>
                {searchProducts.length
                    ? searchProducts.map((product) => (
                          <Dropdown.ItemText
                              role='menuitem'
                              className='search-item'
                              key={product._id}
                          >
                              <Item style={true} product={product} />
                          </Dropdown.ItemText>
                      ))
                    : null}
            </Dropdown.Menu>
        </Dropdown>
    );
};
export default SearchBar;

// import { useState } from "react";
// import { InputGroup, Button, Form, ListGroup } from "react-bootstrap";
// import { SearchIcon } from "../icons";
// import "./index.scss";

// const SearchBar = () => {
//     const [openList, setOpenList] = useState(false);

//     return (
//         <div className='search-form-bar-wrapper'>

//             {openList && (
//                 <ListGroup className='my-2'>
//                     <ListGroup.Item>This ListGroup</ListGroup.Item>
//                     <ListGroup.Item>renders horizontally</ListGroup.Item>
//                     <ListGroup.Item>on asdfjkas dasdf asdfa</ListGroup.Item>
//                     <ListGroup.Item>and above!</ListGroup.Item>
//                     <ListGroup.Item>This ListGroup</ListGroup.Item>
//                     <ListGroup.Item>renders horizontally</ListGroup.Item>
//                     <ListGroup.Item>on asdfjkas dasdf asdfa</ListGroup.Item>
//                     <ListGroup.Item>and above!</ListGroup.Item>
//                     <ListGroup.Item>This ListGroup</ListGroup.Item>
//                     <ListGroup.Item>renders horizontally</ListGroup.Item>
//                     <ListGroup.Item>on asdfjkas dasdf asdfa</ListGroup.Item>
//                     <ListGroup.Item>and above!</ListGroup.Item>
//                     <ListGroup.Item>This ListGroup</ListGroup.Item>
//                     <ListGroup.Item>renders horizontally</ListGroup.Item>
//                     <ListGroup.Item>on asdfjkas dasdf asdfa</ListGroup.Item>
//                     <ListGroup.Item>and above!</ListGroup.Item>
//                 </ListGroup>
//             )}
//         </div>
//     );
// };

// export default SearchBar;
