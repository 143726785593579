import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { AlertIcon } from "../../components/icons";
import AwareButton from "../../components/button";
import "./index.scss";

const CartExpiredAlertWithExtendTImeModal = ({
    show,
    handleClose,
    extendCartTime,
    isLoading
}) => {
    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                        <div className='product-added-success-modal'>
                            <div className='text-center mb-3'>
                                <div className='body-content'>
                                    <div className='icon-box d-flex justify-content-center'>
                                        <AlertIcon />
                                    </div>
                                    <h5>Your cart will expire soon</h5>
                                    <p>
                                        The inventory in your reserved cart will
                                        expire soon. Click the button below to
                                        extend your reservation by 10 minutes.
                                    </p>
                                </div>
                                <div className='d-flex justify-content-center mb-4'>
                                    <AwareButton
                                        onClick={() =>
                                            !isLoading && extendCartTime()
                                        }
                                        className='btn btn-lg btn-green w-100'
                                    >
                                        Add 10 Minutes
                                    </AwareButton>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default CartExpiredAlertWithExtendTImeModal;
