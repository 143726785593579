import { ListIcon, TileIcon } from "../icons";
import "./index.scss";

const Toggle = ({ status, setStatus }) => {
    return (
        <div
            className='list-toggle d-flex align-items-center'
            onClick={() => {
                setStatus(!status);
            }}
        >
            <span className={`toggle-item ${status ? "active" : ""}`}>
                <TileIcon width='24' height='24' />
                <span className='d-none d-md-block'></span>
            </span>
            <span className={`toggle-item ${status ? "" : "active"}`}>
                <ListIcon width='24' height='24' />
                <span className='d-none d-md-block'></span>
            </span>
        </div>
    );
};

export default Toggle;
