/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Collapse,
    Form,
    Row,
    Spinner,
    Container,
    Dropdown
} from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBetween } from "use-between";
import qs from "query-string";

import { Item, Toggle } from "../../components";
import { CloseIcon, XIcon, DownArrowIcon } from "../../components/icons";
import CartLayout from "../../layout/CartLayout";
import useApi from "../useApi";

import "./index.scss";
import InfiniteScroll from "./InfiniteScroll";
import ProductsLoader from "../../components/products-loader";
import ProductsTabularLoader from "../../components/products-loader/ProductsTabularLoader";

const Collection = () => {
    const params = useParams();
    const cBrand = params.brand;
    const navigate = useNavigate();
    const pageSize = 350;
    const [category, setCategory] = useState(false);
    const [phenotype, setPhenotype] = useState(false);
    const [brand, setBrand] = useState(true);
    const [sortByValue, setSortByValue] = useState("low");
    const [filter, setFilter] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [isTileView, setIsTileView] = useState(
        localStorage.getItem("isTileView")
            ? JSON.parse(localStorage.getItem("isTileView"))
            : true
    );
    const [totalCount, setTotalCount] = useState(0);
    const [status, setStatus] = useState(false);

    const setView = (status) => {
        setIsTileView(status);
        localStorage.setItem("isTileView", JSON.stringify(status));
    };

    const query = useQuery();
    const location = useLocation();

    const {
        customerId,
        multipleCustomers,
        products,
        updating,
        availabilityFilters,
        brandFilters,
        categoryFilters,
        phenotypeFilters,
        tasteFilters,
        terpineFilters,
        categories,
        phenotypes,
        effectFilters,
        subFilters,
        setProducts,
        getProductsCategories,
        getProducts,
        getFavorite,
        setAvailabilityFilters,
        setCategoryFilters,
        setPhenotypeFilters,
        setBrandFilters,
        setEffectFilters,
        setSubFilters,
        setTasteFilters,
        setTerpineFilters,
        logout
    } = useBetween(useApi);

    const tabs = [
        "Heavy Hitters",
        "Almora",
        "Lift Tickets",
        "Surplus",
        "Higher",
        "Beard Brothers"
    ];

    const productAvailability = [
        { id: "new-release", label: "New" },
        { id: "limited-product", label: "Limited Supply" },
        { id: "fast-movers", label: "Fast Movers" }
    ];

    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const updateQuery = (
        availability,
        brand,
        category,
        subCategory,
        taste,
        terpine,
        phenotype,
        effect,
        sortByValue
    ) => {
        const newQueryParam = {
            availability: availability.join(","),
            brand: brand.join(","),
            category: category.join(","),
            subcategory: subCategory.join(","),
            taste: taste.join(","),
            terpine: terpine.join(","),
            phenotype: phenotype.join(","),
            effect: effect.join(","),
            sort: sortByValue
        };
        setPageNum(1);
        setProducts([]);

        navigate(`/collection/${cBrand}?${qs.stringify(newQueryParam)}`);
    };

    useEffect(() => {
        if (cBrand !== "all") {
            setCategoryFilters([]);
        }
    }, [cBrand]);

    useEffect(() => {
        getProductsCategories().catch((err) => {
            console.log("err", err);
        });
    }, []);

    useEffect(() => {
        getFavorite(customerId);
    }, [customerId]);

    useEffect(() => {
        setStatus(false);
        let tempAvailabilities = query.get("availability")
            ? query.get("availability").split(",")
            : [];
        setAvailabilityFilters(tempAvailabilities);
        let tempBrands = query.get("brand")
            ? query.get("brand").split(",")
            : [];
        setBrandFilters(tempBrands);
        let tempCategories = query.get("category")
            ? query.get("category").split(",")
            : [];
        setCategoryFilters(tempCategories);
        let tempTastes = query.get("taste")
            ? query.get("taste").split(",")
            : [];
        setTasteFilters(tempTastes);
        let tempTerpines = query.get("terpine")
            ? query.get("terpine").split(",")
            : [];
        setTerpineFilters(tempTerpines);
        let tempPhenotypes = query.get("phenotype")
            ? query.get("phenotype").split(",")
            : [];
        setPhenotypeFilters(tempPhenotypes);
        let tempEffects = query.get("effect")
            ? query.get("effect").split(",")
            : [];
        setEffectFilters(tempEffects);
        let tempSubCategories = query.get("subcategory")
            ? query.get("subcategory").split(",")
            : [];
        setSubFilters(tempSubCategories);

        const pageNumber =
            tempAvailabilities.length <= 0 ||
            tempBrands.length <= 0 ||
            tempCategories.length <= 0 ||
            tempSubCategories.length <= 0 ||
            tempTastes.length <= 0 ||
            tempTerpines.length <= 0 ||
            tempPhenotypes.length <= 0 ||
            tempEffects.length <= 0
                ? 1
                : pageNum;

        getProducts(
            tempAvailabilities,
            tempBrands,
            tempCategories,
            tempSubCategories,
            tempTastes,
            tempTerpines,
            tempPhenotypes,
            tempEffects,
            sortByValue,
            pageNumber,
            pageSize
        )
            .then((res) => {
                setTotalCount(res.count);
                setPageNum(pageNum + 1);
                setStatus(true);
            })
            .catch(() => logout());
    }, [location.search, cBrand]);

    const loadMoreProducts = () => {
        const maxPagination = totalCount / pageSize + 1;
        if (!status || pageNum > maxPagination) {
            return false;
        }

        setStatus(false);
        let tempAvailabilities = query.get("availability")
            ? query.get("availability").split(",")
            : [];
        setAvailabilityFilters(tempAvailabilities);
        let tempBrands = query.get("brand")
            ? query.get("brand").split(",")
            : [];
        setBrandFilters(tempBrands);
        let tempCategories = query.get("category")
            ? query.get("category").split(",")
            : [];
        setCategoryFilters(tempCategories);
        let tempTastes = query.get("taste")
            ? query.get("taste").split(",")
            : [];
        setTasteFilters(tempTastes);
        let tempTerpines = query.get("terpine")
            ? query.get("terpine").split(",")
            : [];
        setTerpineFilters(tempTerpines);
        let tempPhenotypes = query.get("phenotype")
            ? query.get("phenotype").split(",")
            : [];
        setPhenotypeFilters(tempPhenotypes);
        let tempEffects = query.get("effect")
            ? query.get("effect").split(",")
            : [];
        setEffectFilters(tempEffects);
        let tempSubCategories = query.get("subcategory")
            ? query.get("subcategory").split(",")
            : [];
        setSubFilters(tempSubCategories);

        getProducts(
            tempAvailabilities,
            tempBrands,
            tempCategories,
            tempSubCategories,
            tempTastes,
            tempTerpines,
            tempPhenotypes,
            tempEffects,
            sortByValue,
            pageNum,
            pageSize
        )
            .then((res) => {
                setStatus(true);
                setPageNum(pageNum + 1);
            })
            .catch(() => logout());
    };

    const setFilterByAvailability = (availability) => {
        let tempAvailabilities = [...availabilityFilters];

        if (tempAvailabilities.includes(availability)) {
            tempAvailabilities.splice(
                tempAvailabilities.indexOf(availability),
                1
            );
        } else {
            tempAvailabilities.push(availability);
        }

        setAvailabilityFilters(tempAvailabilities);

        updateQuery(
            tempAvailabilities,
            brandFilters,
            categoryFilters,
            subFilters,
            tasteFilters,
            terpineFilters,
            phenotypeFilters,
            effectFilters,
            sortByValue
        );
    };

    const setFilterByBrand = (brand) => {
        let tempBrands = [...brandFilters];

        if (tempBrands.includes(brand)) {
            tempBrands.splice(tempBrands.indexOf(brand), 1);
        } else {
            tempBrands.push(brand);
        }

        setBrandFilters(tempBrands);
        updateQuery(
            availabilityFilters,
            tempBrands,
            categoryFilters,
            subFilters,
            tasteFilters,
            terpineFilters,
            phenotypeFilters,
            effectFilters,
            sortByValue
        );
    };

    const setFilterByCategory = (category) => {
        let tempCategories = [...categoryFilters];

        if (tempCategories.includes(category)) {
            tempCategories.splice(tempCategories.indexOf(category), 1);
        } else {
            tempCategories.push(category);
        }

        setCategoryFilters(tempCategories);
        updateQuery(
            availabilityFilters,
            brandFilters,
            tempCategories,
            [],
            tasteFilters,
            terpineFilters,
            phenotypeFilters,
            effectFilters,
            sortByValue
        );
    };

    const setFilterByTerpine = (terpine) => {
        let tempTerpines = [...terpineFilters];

        if (tempTerpines.includes(terpine)) {
            tempTerpines.splice(tempTerpines.indexOf(terpine), 1);
        } else {
            tempTerpines.push(terpine);
        }

        setTerpineFilters(tempTerpines);
        updateQuery(
            availabilityFilters,
            brandFilters,
            categoryFilters,
            subFilters,
            tasteFilters,
            tempTerpines,
            phenotypeFilters,
            effectFilters,
            sortByValue
        );
    };

    const setFilterByTaste = (taste) => {
        let tempTastes = [...tasteFilters];

        if (tempTastes.includes(taste)) {
            tempTastes.splice(tempTastes.indexOf(taste), 1);
        } else {
            tempTastes.push(taste);
        }

        setTasteFilters(tempTastes);
        updateQuery(
            availabilityFilters,
            brandFilters,
            categoryFilters,
            subFilters,
            tempTastes,
            terpineFilters,
            phenotypeFilters,
            effectFilters,
            sortByValue
        );
    };

    const setFilterByPhenotype = (phenotype) => {
        let tempPhenotypes = [...phenotypeFilters];

        if (tempPhenotypes.includes(phenotype)) {
            tempPhenotypes.splice(tempPhenotypes.indexOf(phenotype), 1);
        } else {
            tempPhenotypes.push(phenotype);
        }

        setPhenotypeFilters(tempPhenotypes);
        updateQuery(
            availabilityFilters,
            brandFilters,
            categoryFilters,
            subFilters,
            tasteFilters,
            terpineFilters,
            tempPhenotypes,
            effectFilters,
            sortByValue
        );
    };

    const setFilterByEffect = (effect) => {
        let tempEffects = [...effectFilters];

        if (tempEffects.includes(effect)) {
            tempEffects.splice(tempEffects.indexOf(effect), 1);
        } else {
            tempEffects.push(effect);
        }

        setEffectFilters(tempEffects);
        updateQuery(
            availabilityFilters,
            brandFilters,
            categoryFilters,
            subFilters,
            tasteFilters,
            terpineFilters,
            phenotypeFilters,
            tempEffects,
            sortByValue
        );
    };

    const setFilterBySubCategories = (subCategory) => {
        let tempSubCategories = [...subFilters];

        if (tempSubCategories.includes(subCategory)) {
            tempSubCategories.splice(tempSubCategories.indexOf(subCategory), 1);
        } else {
            tempSubCategories.push(subCategory);
        }

        setSubFilters(tempSubCategories);
        updateQuery(
            availabilityFilters,
            brandFilters,
            categoryFilters,
            tempSubCategories,
            tasteFilters,
            terpineFilters,
            phenotypeFilters,
            effectFilters,
            sortByValue
        );
    };

    const handleSortBy = (sort) => {
        setSortByValue(sort);
        updateQuery(
            availabilityFilters,
            brandFilters,
            categoryFilters,
            subFilters,
            tasteFilters,
            terpineFilters,
            phenotypeFilters,
            effectFilters,
            sort
        );
    };

    const handleResetFilter = () => {
        updateQuery([], [], [], [], [], [], [], [], "");
    };

    return (
        <CartLayout>
            <div className='w-100 has-sidebar pt-5 pb-4'>
                <Container>
                    <div className='page-header'>
                        <h3>All Products</h3>
                    </div>
                    <Row className='filter-bar'>
                        <Col sm={9} md={10}>
                            <ul className='filter-items-wrapper'>
                                {productAvailability.map((availability) => (
                                    <li
                                        className='filter-column'
                                        key={availability.id}
                                    >
                                        <Button
                                            size='sm'
                                            variant='outline-secondary'
                                            className={
                                                availabilityFilters.includes(
                                                    availability.id
                                                )
                                                    ? `active ${availability.id}`
                                                    : `${availability.id}`
                                            }
                                            onClick={() =>
                                                setFilterByAvailability(
                                                    availability.id
                                                )
                                            }
                                        >
                                            {availability.label}
                                        </Button>
                                    </li>
                                ))}
                                <li className='filter-column'>
                                    <Dropdown
                                        className='filter-dropdown'
                                        autoClose='outside'
                                    >
                                        <Dropdown.Toggle
                                            variant='outline-dark'
                                            size='sm'
                                        >
                                            Brand
                                            <span className='ms-1'>
                                                {brandFilters.length > 0 &&
                                                    `[${brandFilters.length}]`}
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu renderOnMount={true}>
                                            {!status && (
                                                <div className='filter-loader'>
                                                    <Spinner size='sm' />
                                                </div>
                                            )}
                                            {tabs.map((brand, index) => (
                                                <Dropdown.Item
                                                    as='div'
                                                    key={brand}
                                                    className='py-0 ps-3 pe-0 filter-dropdown-item'
                                                >
                                                    <Form.Check
                                                        key={index}
                                                        type='checkbox'
                                                        id={`brand-checkbox-${index}`}
                                                        label={brand}
                                                        name='brand'
                                                        value={brand}
                                                        checked={brandFilters.includes(
                                                            brand
                                                        )}
                                                        className='d-block'
                                                        onChange={(e) =>
                                                            setFilterByBrand(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                <li className='filter-column'>
                                    <Dropdown
                                        className='filter-dropdown'
                                        autoClose='outside'
                                    >
                                        <Dropdown.Toggle
                                            variant='outline-dark'
                                            size='sm'
                                        >
                                            Phenotype
                                            <span className='ms-1'>
                                                {phenotypeFilters.length > 0 &&
                                                    `[${phenotypeFilters.length}]`}
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu renderOnMount={true}>
                                            {!status && (
                                                <div className='filter-loader'>
                                                    <Spinner size='sm' />
                                                </div>
                                            )}
                                            {phenotypes.length
                                                ? phenotypes.map(
                                                      (phenotype, index) => (
                                                          <Dropdown.Item
                                                              as='div'
                                                              key={index}
                                                              className='py-0 ps-3 pe-0 filter-dropdown-item'
                                                          >
                                                              <Form.Check
                                                                  key={index}
                                                                  type='checkbox'
                                                                  id={`pheno-checkbox-${index}`}
                                                                  label={
                                                                      phenotype
                                                                  }
                                                                  name='phenotype'
                                                                  value={
                                                                      phenotype
                                                                  }
                                                                  checked={phenotypeFilters.includes(
                                                                      phenotype
                                                                  )}
                                                                  onChange={(
                                                                      e
                                                                  ) =>
                                                                      setFilterByPhenotype(
                                                                          e
                                                                              .target
                                                                              .value
                                                                      )
                                                                  }
                                                              />
                                                          </Dropdown.Item>
                                                      )
                                                  )
                                                : null}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>

                                <li className='filter-column'>
                                    <Dropdown
                                        className='filter-dropdown'
                                        autoClose='outside'
                                    >
                                        <Dropdown.Toggle
                                            variant='outline-dark'
                                            size='sm'
                                        >
                                            Category
                                            <span className='ms-1'>
                                                {categoryFilters.length > 0 &&
                                                    `[${categoryFilters.length}]`}
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu renderOnMount={true}>
                                            {!status && (
                                                <div className='filter-loader'>
                                                    <Spinner size='sm' />
                                                </div>
                                            )}
                                            {Object.keys(categories).length
                                                ? Object.keys(categories).map(
                                                      (category, index) =>
                                                          categories[category]
                                                              .available ? (
                                                              <Dropdown.Item
                                                                  as='div'
                                                                  key={index}
                                                                  className='py-0 ps-3 pe-0 filter-dropdown-item'
                                                              >
                                                                  <Form.Check
                                                                      type='checkbox'
                                                                      id={`category-checkbox-${index}`}
                                                                      label={
                                                                          category
                                                                      }
                                                                      name='category'
                                                                      value={
                                                                          category
                                                                      }
                                                                      className=''
                                                                      checked={categoryFilters.includes(
                                                                          category
                                                                      )}
                                                                      onChange={(
                                                                          e
                                                                      ) => {
                                                                          setFilterByCategory(
                                                                              e
                                                                                  .target
                                                                                  .value
                                                                          );
                                                                      }}
                                                                  />
                                                              </Dropdown.Item>
                                                          ) : null
                                                  )
                                                : null}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                                {categoryFilters.length > 0 && (
                                    <li className='filter-column'>
                                        <Dropdown
                                            className={`filter-dropdown`}
                                            autoClose='outside'
                                        >
                                            <Dropdown.Toggle
                                                variant='outline-dark'
                                                size='sm'
                                                disabled={
                                                    !(
                                                        categoryFilters.length >
                                                        0
                                                    )
                                                }
                                            >
                                                Type
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu renderOnMount={true}>
                                                {!status && (
                                                    <div className='filter-loader'>
                                                        <Spinner size='sm' />
                                                    </div>
                                                )}
                                                {Object.keys(categories).length
                                                    ? Object.keys(
                                                          categories
                                                      ).map((category, index) =>
                                                          categories[category]
                                                              .available ? (
                                                              <Dropdown.Item
                                                                  as='div'
                                                                  key={index}
                                                                  className='py-0 px-0 filter-dropdown-item'
                                                              >
                                                                  <div className='menu-category-name px-3 py-2'>
                                                                      {category}
                                                                  </div>
                                                                  {categories[
                                                                      category
                                                                  ].subCategories
                                                                      .sort()
                                                                      .reverse()
                                                                      .map(
                                                                          (
                                                                              subCategory,
                                                                              index
                                                                          ) => (
                                                                              <Dropdown.Item
                                                                                  as='div'
                                                                                  key={
                                                                                      index
                                                                                  }
                                                                                  className='py-0 ps-3 pe-0 filter-dropdown-item'
                                                                              >
                                                                                  <Form.Check
                                                                                      key={
                                                                                          index
                                                                                      }
                                                                                      type='checkbox'
                                                                                      id={`sub-checkbox-${index}`}
                                                                                      label={
                                                                                          subCategory
                                                                                      }
                                                                                      name='subcategory'
                                                                                      value={
                                                                                          subCategory
                                                                                      }
                                                                                      className=''
                                                                                      checked={subFilters.includes(
                                                                                          subCategory
                                                                                      )}
                                                                                      onChange={(
                                                                                          e
                                                                                      ) =>
                                                                                          setFilterBySubCategories(
                                                                                              e
                                                                                                  .target
                                                                                                  .value
                                                                                          )
                                                                                      }
                                                                                  />
                                                                              </Dropdown.Item>
                                                                          )
                                                                      )}
                                                              </Dropdown.Item>
                                                          ) : null
                                                      )
                                                    : null}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                )}
                                {(availabilityFilters.length > 0 ||
                                    brandFilters.length > 0 ||
                                    categoryFilters.length > 0 ||
                                    phenotypeFilters.length > 0 ||
                                    subFilters.length > 0) && (
                                    <li className='filter-column'>
                                        <Button
                                            size='sm'
                                            variant='outline-danger'
                                            className='reset-button'
                                            onClick={() => handleResetFilter()}
                                        >
                                            Reset
                                        </Button>
                                    </li>
                                )}
                            </ul>
                        </Col>
                        <Col sm={3} md={2}>
                            <Toggle status={isTileView} setStatus={setView} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className='bg-white d-flex flex-column'>
                            <div className='collection-container'>
                                {(brandFilters.length > 0 ||
                                    categoryFilters.length > 0 ||
                                    subFilters.length > 0 ||
                                    terpineFilters.length > 0 ||
                                    tasteFilters.length > 0 ||
                                    phenotypeFilters.length > 0 ||
                                    effectFilters.length > 0) && (
                                    <div className='collection-fil tags-wrapper'>
                                        <div>
                                            {brandFilters.map((val, index) => (
                                                <span
                                                    className='filter-item'
                                                    key={index}
                                                    onClick={() => {
                                                        setFilterByBrand(val);
                                                    }}
                                                >
                                                    <span>{val}</span>
                                                    <CloseIcon
                                                        width='14'
                                                        height='14'
                                                    />
                                                </span>
                                            ))}
                                            {categoryFilters.map(
                                                (val, index) => (
                                                    <span
                                                        className='filter-item'
                                                        key={index}
                                                        onClick={() => {
                                                            setFilterByCategory(
                                                                val
                                                            );
                                                        }}
                                                    >
                                                        <span>{val}</span>
                                                        <CloseIcon
                                                            width='14'
                                                            height='14'
                                                        />
                                                    </span>
                                                )
                                            )}
                                            {subFilters.map((val, index) => (
                                                <span
                                                    className='filter-item'
                                                    key={index}
                                                    onClick={() => {
                                                        setFilterBySubCategories(
                                                            val
                                                        );
                                                    }}
                                                >
                                                    <span>{val}</span>
                                                    <CloseIcon
                                                        width='14'
                                                        height='14'
                                                    />
                                                </span>
                                            ))}
                                            {terpineFilters.map(
                                                (val, index) => (
                                                    <span
                                                        className='filter-item'
                                                        key={index}
                                                        onClick={() => {
                                                            setFilterByTerpine(
                                                                val
                                                            );
                                                        }}
                                                    >
                                                        <span>{val}</span>
                                                        <CloseIcon
                                                            width='14'
                                                            height='14'
                                                        />
                                                    </span>
                                                )
                                            )}
                                            {tasteFilters.map((val, index) => (
                                                <span
                                                    className='filter-item'
                                                    key={index}
                                                    onClick={() => {
                                                        setFilterByTaste(val);
                                                    }}
                                                >
                                                    <span>{val}</span>
                                                    <CloseIcon
                                                        width='14'
                                                        height='14'
                                                    />
                                                </span>
                                            ))}
                                            {phenotypeFilters.map(
                                                (val, index) => (
                                                    <span
                                                        className='filter-item'
                                                        key={index}
                                                        onClick={() => {
                                                            setFilterByPhenotype(
                                                                val
                                                            );
                                                        }}
                                                    >
                                                        <span>{val}</span>
                                                        <CloseIcon
                                                            width='14'
                                                            height='14'
                                                        />
                                                    </span>
                                                )
                                            )}
                                            {effectFilters.map((val, index) => (
                                                <span
                                                    className='filter-item'
                                                    key={index}
                                                    onClick={() => {
                                                        setFilterByEffect(val);
                                                    }}
                                                >
                                                    <span>{val}</span>
                                                    <CloseIcon
                                                        width='14'
                                                        height='14'
                                                    />
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className='products-wrapper'>
                                    {isTileView ? (
                                        <Row>
                                            {products.length
                                                ? products.map(
                                                      (product, index) => (
                                                          <Col
                                                              key={`${product._id}-${index}`}
                                                              lg={2}
                                                              md={3}
                                                              xs={6}
                                                          >
                                                              <Item
                                                                  style={
                                                                      isTileView
                                                                  }
                                                                  product={
                                                                      product
                                                                  }
                                                              />
                                                          </Col>
                                                      )
                                                  )
                                                : null}
                                            {!status && <ProductsLoader />}
                                            {products.length <= 0 && status && (
                                                <h6>No product available!</h6>
                                            )}
                                        </Row>
                                    ) : (
                                        <div className='product-table'>
                                            <div className='table-header'>
                                                <span className='table-cell title-img d-flex align-items-center'>
                                                    <span className='me-3 d-sm-block d-none'></span>
                                                    <span>Products</span>
                                                </span>
                                                <span className='table-cell d-md-block d-none potency'>
                                                    THC %
                                                </span>
                                                <span className='table-cell d-lg-block d-none'>
                                                    Unit Price
                                                </span>
                                                <span className='table-cell size d-xl-block d-none'>
                                                    Case Size
                                                </span>
                                                <span className='table-cell'>
                                                    Case Price
                                                </span>
                                                <span className='table-cell add-cta me-0'>
                                                    Add
                                                </span>
                                            </div>
                                            {products.length
                                                ? products.map(
                                                      (product, index) => (
                                                          <Item
                                                              key={`${product._id}-${index}`}
                                                              style={isTileView}
                                                              product={product}
                                                          />
                                                      )
                                                  )
                                                : null}

                                            {!status && (
                                                <ProductsTabularLoader />
                                            )}
                                            {products.length <= 0 && status && (
                                                <h6>No product available!</h6>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <InfiniteScroll onIntersect={loadMoreProducts} />
            </div>
            {/* {!status ? (
                <div className='page-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null} */}
            {updating ? (
                <div className='update-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
            {/* <ScrollToTop /> */}
        </CartLayout>
    );
};

export default Collection;
