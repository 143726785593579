import { Container } from "react-bootstrap";
import Navbar from "../../components/navbar";
import Layout from "../../layout";

import "./index.scss";

const Notifications = () => {
  return (
    <Layout>
      <div className="bg-gray">
        <Container className="notifications-container d-flex">
          <Navbar active="notification" />
          <div className="notifications-wrapper">
            <h4>Notifications</h4>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

export default Notifications;
