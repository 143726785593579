/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useBetween } from "use-between";

import { Item, Toggle } from "../../components";
import Navbar from "../../components/navbar";
import Layout from "../../layout";
import useApi from "../useApi";

import "./index.scss";

const Favorites = () => {
    const [isTileView, setIsTileView] = useState(true);
    const {
        updating,
        customerId,
        favorite,
        favoriteProducts,
        getFavorite,
        setUpdating,
        logout
    } = useBetween(useApi);

    useEffect(() => {
        setUpdating(true);
        getFavorite(customerId)
            .then(() => setUpdating(false))
            .catch((err) => {
                if (err.message === "Session expired, please login again!") {
                    logout();
                } else {
                    setUpdating(false);
                }
            });
    }, [customerId]);

    return (
        <Layout>
            <div className='bg-gray'>
                <Container className='favorites-container d-flex'>
                    <Navbar active='favorites' />
                    <div className='favorites-wrapper'>
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <div className='title-wrapper d-flex align-items-center'>
                                <h4>Favorites</h4>
                                <span className='dot mx-2' />
                                <span className='count'>
                                    {Object.keys(favorite).length
                                        ? favorite.products.length
                                        : 0}{" "}
                                    Products
                                </span>
                            </div>
                            <Toggle
                                status={isTileView}
                                setStatus={setIsTileView}
                            />
                        </div>

                        {isTileView ? (
                            <Row>
                                {Object.keys(favorite).length
                                    ? favorite.products.map((item) => (
                                          <Col key={item} xl={3} sm={4} xs={6}>
                                              <Item
                                                  product={
                                                      favoriteProducts[item]
                                                  }
                                                  style={isTileView}
                                              />
                                          </Col>
                                      ))
                                    : null}
                            </Row>
                        ) : (
                            <div className='product-table'>
                                <div className='table-header'>
                                    <span className='table-cell title-img d-flex align-items-center'>
                                        <span className='blank-img me-3 d-sm-block d-none'></span>
                                        <span>Products</span>
                                    </span>
                                    <span className='table-cell d-sm-block d-none'>
                                        Phenotype
                                    </span>
                                    <span className='table-cell d-md-block d-none'>
                                        THC %
                                    </span>
                                    <span className='table-cell d-lg-block d-none'>
                                        Unit Price
                                    </span>
                                    <span className='table-cell size d-xl-block d-none'>
                                        Case Size
                                    </span>
                                    <span className='table-cell'>
                                        Case Price
                                    </span>
                                    <span className='table-cell add-cta me-0'>
                                        Add
                                    </span>
                                </div>
                                {Object.keys(favorite).length
                                    ? favorite.products.map((item) => (
                                          <Item
                                              key={item}
                                              style={isTileView}
                                              product={favoriteProducts[item]}
                                          />
                                      ))
                                    : null}
                            </div>
                        )}
                    </div>
                </Container>
            </div>
            {updating ? (
                <div className='page-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
        </Layout>
    );
};

export default Favorites;
