/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useBetween } from "use-between";
import "./index.scss";

import useApi from "../../pages/useApi";
import { Dropdown, Spinner } from "react-bootstrap";
import { DownArrowIcon } from "../icons";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=''
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className='d-inline-flex align-items-center'
    >
        {children}
        <DownArrowIcon stroke='#3661D0' />
    </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        const [value] = useState("");

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className='list-unstyled account-lists'>
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value ||
                            child.props.children.toLowerCase().startsWith(value)
                    )}
                </ul>
            </div>
        );
    }
);

const AccountSelector = () => {
    const [isloading, setIsloading] = useState(false);
    const {
        customer,
        customerId,
        getCustomer,
        logout,
        childCustomers,
        setCustomerId
    } = useBetween(useApi);

    const changeCustomer = (id) => {
        setCustomerId(id);
        localStorage.setItem("customerId", id);
    };

    useEffect(() => {
        setIsloading(true);
        getCustomer(customerId)
            .finally(() => setIsloading(false))
            .catch(() => logout());
    }, [customerId]);

    return (
        <div className='d-flex justify-content-center account-switcher-container'>
            <Dropdown>
                <Dropdown.Toggle
                    as={CustomToggle}
                    className='account-switcher-menu-btn'
                >
                    Shopping for:
                    <span className='ms-1 me-2 d-inline-flex align-items-center'>
                        {customer.AccountRef}
                        {isloading && <Spinner size='sm' className='ms-1' />}
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                    {childCustomers &&
                        childCustomers.length > 0 &&
                        childCustomers.map((customer) => (
                            <Dropdown.Item
                                eventKey='1'
                                key={customer.customerId}
                                onClick={() =>
                                    changeCustomer(customer.customerId)
                                }
                                style={{
                                    color:
                                        customer.customerId === customerId
                                            ? "#3661d0"
                                            : "#202020"
                                }}
                            >
                                {customer.accountRef}
                                <div>{customer.licenseNo}</div>
                            </Dropdown.Item>
                        ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default AccountSelector;
