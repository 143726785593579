/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Container, Image, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { LeftArrow } from "../../components/icons";
import Navbar from "../../components/navbar";
import Layout from "../../layout";
import ItemImage from "../../assets/images/collection/product.png";
import { useBetween } from "use-between";
import useApi from "../useApi";

import "./index.scss";
import { useEffect, useState } from "react";
import {
    formatPhoneNumber,
    toDate,
    toDateType,
    toTwoDecimals
} from "../../plugins";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import Invoice from "./invoice";
import AwareButton from "../../components/button";
import SuccessModal from "./SuccessModal";
import OutOfStockProductsModal from "./OutOfStockProductsModal";
import SimilarProductsModal from "./SimilarProductsModal";

const Order = () => {
    const invoiceData = {
        invoice_no: "",
        balance: "",
        company: "",
        email: "",
        phone: "",
        address: "",
        trans_date: "",
        due_date: "",
        items: []
    };

    const params = useParams();
    const navigate = useNavigate();
    const {
        updating,
        customer,
        customerId,
        multipleCustomers,
        order,
        getOrder,
        getCustomer,
        getProductInventory,
        setUpdating,
        logout,
        rebuildCart,
        getCart,
        cartOpen,
        setCartOpen
    } = useBetween(useApi);

    const [totalAmount, setTotalAmount] = useState(0);
    const [invoice, setInvoice] = useState({});
    const [pending, setPending] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [showSimilarProductsModal, setShowSimilarProductsModal] = useState(
        false
    );
    const [unavailableProducts, setUnavailableProducts] = useState([]);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [findSimilarProductId, setFindSimilarProductId] = useState("");

    const orderId = params.id;

    const handleClick = (id) => {
        getProductInventory(id).then((res) => {
            navigate(`/product/${res.product._id}`);
        });
    };

    const handleRebuild = () => {
        setPending(true);
        rebuildCart(orderId)
            .then((res) => {
                if (res.type === "success") {
                    if (res.unavailableProducts.length < 1) {
                        setShowSuccessModal(true);
                    } else {
                        setUnavailableProducts(res.unavailableProducts);
                        setShowProductsModal(true);
                        setPending(false);
                    }
                    getCart(customerId).then(() => {
                        setPending(false);
                    });
                }
            })
            .catch(() => setPending(false));
    };

    const handleNavigation = () => {
        navigate(`/collection/all`);
        setCartOpen(!cartOpen);
    };

    useEffect(() => {
        setUpdating(true);
        getCustomer(customerId);
        getOrder(orderId)
            .then(() => setUpdating(false))
            .catch(() => logout());
    }, [orderId]);

    useEffect(() => {
        if (Object.keys(order).length && Object.keys(customer).length) {
            let orderAmount = 0;
            order.Details.map((data, i) => {
                orderAmount += data.OrderQty.value * data.DefaultPrice.value;
                invoiceData.items.push({
                    sno: i + 1,
                    desc: data.product
                        ? data.product.Buyermenuproductname.value
                        : data.SKUDescription.value,
                    qty: data.OrderQty.value,
                    rate: toTwoDecimals(data.DefaultPrice.value)
                });
            });

            invoiceData.invoice_no = order.OrderNbr.value
                ? order.OrderNbr.value.replace("SO", "INVOICE")
                : toDate(order.createdAt);
            invoiceData.balance = toTwoDecimals(orderAmount);
            invoiceData.company = customer.AccountRef;
            invoiceData.email = customer.Email;
            invoiceData.phone = customer.Phone1;
            invoiceData.trans_date = toDateType(order.createdAt);
            invoiceData.due_date = toDateType(order.RequestedOn.value);
            setInvoice(invoiceData);
            setTotalAmount(orderAmount);
        }
    }, [order, customer]);

    const removeProduct = (productId) => {
        const productIndex = unavailableProducts.findIndex(
            (product) => product.id === productId
        );
        unavailableProducts.splice(productIndex, 1);
        setUnavailableProducts([...unavailableProducts]);
    };

    return (
        <Layout>
            <div className='bg-gray'>
                {Object.keys(order).length && Object.keys(customer).length ? (
                    <Container
                        className={`order-container d-flex ${
                            multipleCustomers ? "hasMultipleCustomers" : ""
                        }`}
                    >
                        <Navbar active='orders' />

                        <div className='order-wrapper'>
                            <div className='title-wrapper d-flex align-items-center justify-content-between'>
                                <h4
                                    onClick={() => navigate("/profile/orders")}
                                    className='cursor-pointer d-flex align-items-center'
                                >
                                    <LeftArrow
                                        className='me-1'
                                        width='18'
                                        height='18'
                                    />{" "}
                                    Order History
                                </h4>
                                {/* { Object.keys(invoice).length ? <PDFDownloadLink className="download-invoice" document={<Invoice invoice={invoice} />} fileName="invoice.pdf">
                <Button variant="second" className="btn-invoice d-flex align-items-center">
                  <FileIcon className="me-2" width="16" height="16" />
                  <span>Invoice</span>
                </Button>
              </PDFDownloadLink> : null } */}
                            </div>

                            <div className='order-details d-flex justify-content-between'>
                                <div className='order-summary-info d-flex w-100 align-item-center'>
                                    <div>
                                        <h6 className='mb-1'>Order ID</h6>
                                        <p>
                                            {order.synced
                                                ? order.OrderNbr.value
                                                : "Not Available"}
                                        </p>
                                    </div>
                                    <div>
                                        <h6 className='mb-1'>Order Date</h6>
                                        <p>
                                            {order.RequestedOn.value
                                                ? toDateType(
                                                      order.RequestedOn.value
                                                  )
                                                : "Not available"}
                                        </p>
                                    </div>
                                    <div>
                                        <h6 className='mb-1'>Delivery Date</h6>
                                        <p>
                                            {order.DeliveryDate?.value
                                                ? toDateType(
                                                      order.DeliveryDate.value
                                                  )
                                                : "Not available"}
                                        </p>
                                    </div>
                                    <div>
                                        <h6>Status</h6>
                                        <p
                                            data-status={order.Status.value.toLowerCase()}
                                        >
                                            {order.Status.value.replace(
                                                "On Hold",
                                                "Pending"
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='order-rebuild-btn-wrapper d-flex justify-content-between align-items-center'>
                                <label>Ordered Products</label>
                                <AwareButton
                                    onClick={handleRebuild}
                                    className='btn btn-rebuild'
                                >
                                    {pending ? "Rebuilding" : "Rebuild Cart"}
                                    {pending && (
                                        <Spinner
                                            animation='border'
                                            size='sm'
                                            className='ms-2'
                                        />
                                    )}
                                </AwareButton>
                            </div>
                            <div className='order-items'>
                                {order.Details.map((data, index) => (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            handleClick(data.InventoryID.value);
                                        }}
                                        className='order-item d-flex align-items-center justify-content-between cursor-pointer'
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div className='img-wrapper me-2'>
                                                {data.Primaryproductimagefirstdisplayed ? (
                                                    <Image
                                                        src={
                                                            data
                                                                .Primaryproductimagefirstdisplayed
                                                                ?.value
                                                        }
                                                        alt='product'
                                                    />
                                                ) : (
                                                    <Image
                                                        src={ItemImage}
                                                        alt='product'
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                <p className='product-tag mb-1'>
                                                    {`${
                                                        data.product.Brand.value
                                                    } | 
                                                    ${
                                                        data.product
                                                            ? data.product
                                                                  .Buyermenuproductname
                                                                  .value
                                                            : data
                                                                  .SKUDescription
                                                                  .value
                                                    }`}
                                                </p>
                                                <h6 className='product-name d-inline-flex align-items-center justify-content-end'>
                                                    {
                                                        data.StrainDescription
                                                            .value
                                                    }
                                                    {data.product &&
                                                    data.product.Phenotype ? (
                                                        typeof data.product
                                                            .Phenotype ===
                                                            "object" &&
                                                        data.product
                                                            .Phenotype !==
                                                            null ? (
                                                            <span
                                                                className='green-tg'
                                                                data-phenotype={
                                                                    data.product
                                                                        .Phenotype
                                                                        .value
                                                                }
                                                            >
                                                                <span>
                                                                    {data.product.Phenotype.value
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase()}
                                                                </span>
                                                            </span>
                                                        ) : (
                                                            <span
                                                                className='green-tg'
                                                                data-phenotype={
                                                                    data.product
                                                                        .Phenotype
                                                                }
                                                            >
                                                                <span>
                                                                    {data.product.Phenotype.charAt(
                                                                        0
                                                                    ).toUpperCase()}
                                                                </span>
                                                            </span>
                                                        )
                                                    ) : null}
                                                </h6>
                                                {data.product?.BFLink
                                                    ?.value && (
                                                    <div className='download-assets-btn-block'>
                                                        <a
                                                            href={
                                                                data.product
                                                                    ?.BFLink
                                                                    ?.value
                                                            }
                                                            target='_blank'
                                                            download={
                                                                data
                                                                    .StrainDescription
                                                                    .value
                                                            }
                                                        >
                                                            Download Assets
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <h6 className='justify-content-end'>
                                                $
                                                {toTwoDecimals(
                                                    data.DefaultPrice.value *
                                                        data.OrderQty.value
                                                )}
                                            </h6>
                                            <p className='mb-1 mt-1'>
                                                {data.OrderQty.value}
                                                {` unit${
                                                    data.OrderQty.value > 1
                                                        ? "s"
                                                        : ""
                                                }`}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='total-amount p-3 d-flex align-items-center justify-content-end'>
                                <h6>Total</h6>
                                <p className='ms-4 mt-0'>
                                    ${toTwoDecimals(totalAmount)}
                                </p>
                            </div>
                            <div className='order-info d-flex'>
                                <div className='delivery-info'>
                                    <h6 className='mb-2 font-light'>
                                        Delivery Information
                                    </h6>
                                    <p className='mb-2 font-light'>
                                        {customer.CustomerName}
                                    </p>
                                    <p className='font-light'>
                                        {customer.AddressLine1}
                                    </p>
                                    <p className='font-light'>
                                        {customer.City}, {customer.State},{" "}
                                        {customer.PostalCode}
                                        {customer.Country}
                                    </p>
                                    <p className='mb-2 font-light'>
                                        {formatPhoneNumber(customer.Phone1)}
                                    </p>
                                </div>
                                <div>
                                    <h6 className='mb-2 font-light'>
                                        Payment Terms
                                    </h6>
                                    <p className='font-light'>
                                        {customer.Terms}
                                    </p>
                                </div>
                            </div>
                            {order.note.value ? (
                                <div>
                                    <h6 className='font-light mb-2'>Note</h6>
                                    <p className='font-light'>
                                        {order.note.value}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </Container>
                ) : null}

                <SuccessModal
                    show={showSuccessModal}
                    handleClose={() => setShowSuccessModal(false)}
                />
                <OutOfStockProductsModal
                    unavailableProducts={unavailableProducts}
                    setFindSimilarProductId={setFindSimilarProductId}
                    findSimilarProductId={findSimilarProductId}
                    setSimilarProducts={setSimilarProducts}
                    show={showProductsModal}
                    setShowSimilarProductsModal={setShowSimilarProductsModal}
                    handleClose={() => setShowProductsModal(false)}
                    removeProduct={removeProduct}
                />
                <SimilarProductsModal
                    show={showSimilarProductsModal}
                    similarProducts={similarProducts}
                    findSimilarProductId={findSimilarProductId}
                    handleClose={() => setShowSimilarProductsModal(false)}
                    handleBack={() => {
                        setShowProductsModal(true);
                        setShowSimilarProductsModal(false);
                    }}
                    handleDone={(pId) => {
                        setShowProductsModal(true);
                        setShowSimilarProductsModal(false);
                        unavailableProducts.length > 1
                            ? removeProduct(pId)
                            : handleNavigation();
                    }}
                />
            </div>
            {updating ? (
                <div className='update-loading'>
                    <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                </div>
            ) : null}
        </Layout>
    );
};

export default Order;
